import React, { useEffect, useState } from 'react'
import { useList, useObject } from "react-firebase-hooks/database"
import { Navigate, useLocation } from 'react-router-dom'

import { onAuthStateChanged } from 'firebase/auth'
import { set, update, ref, onValue, push, remove, get, child } from 'firebase/database'
import { ref as storageRef, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage'
import { auth, db, storage } from '../firebase'
import { numberWithCommas, setBackColor, span, Toast } from '../module'
import { useAuth } from './AuthProvider'

import UserAlbumsList from './userAlbumsList'

import UserImg from "../Images/User.jpg"
import EditImg from "../Images/Edit.png"
import UploadImg from "../Images/Upload.png"
import ArrowImg from "../Images/Arrow.png"
import { useNavigate } from 'react-router-dom'
import LinksList from './linksList'
import { Loading } from './Loading'

export default function Profile({ user }) {

    const navigate = useNavigate();

    const [opened, setOpened] = useState(false);

    const [publicAccount, setPublic] = useState(false);
    const [desc, setDescription] = useState("");

    const handleDescChange = (event) => {
        const value = event.target.value;
        document.querySelector("#desccount").textContent = numberWithCommas(value.length) + " / 15,000"
        setDescription(value);
    };

    const handlePublicChange = async (event) => {
        const value = event.target.checked;
        setPublic(value);
        await update(ref(db, "users/" + user.uid), {
            public: value
        })
    };

    function openEditFrame() {
        setOpened(!opened);
    };

    async function saveDesc() {
        await update(ref(db, "users/" + user.uid), {
            description: desc
        })
        Toast("Description saved!", 3000)
    };

    async function addLink() {
        await update(ref(db,"users/" + user.uid + "/links"), {
            [document.querySelector("#linknameinput").value]: document.querySelector("#linkinput").value
        })
        document.querySelector("#linknameinput").value = ""
        document.querySelector("#linkinput").value = ""
    };

    async function setPreviewImg() {
        var file = document.querySelector("#changeimage").files[0]
        const imageStorageRef = storageRef(storage, "img/preview/" + user.uid)
        await uploadBytes(imageStorageRef, file).then(async () => {
            await getDownloadURL(imageStorageRef).then((url) => {
                document.querySelector("#imagepreview").src = url
            })
        });
    }

    async function saveProfile(e) {
        e.preventDefault();

        var file = document.querySelector("#changeimage").files[0]
    
        if (file != null) {
            await deleteObject(storageRef(storage, "img/preview/" + user.uid))
            const imageStorageRef = storageRef(storage, "img/profile/" + user.uid)
            await uploadBytes(imageStorageRef, file).then(async () => {
                await getDownloadURL(imageStorageRef).then(async (url) => {
                    await update(ref(db, "users/" + user.uid), {
                        username: document.querySelector("#changeusername").value,
                        image_url: url
                    })
                })
            });
        }
        else {
            await update(ref(db, "users/" + user.uid), {
                username: document.querySelector("#changeusername").value
            })
        }
        Toast("Saved", 3000);
        fetchData();
    }

    async function newAlbum() {
        const reference = await push(ref(db, "albums"))
        await set(reference, {
            title: "New album",
            cover_url: "https://firebasestorage.googleapis.com/v0/b/tdrfirebase-cef0a.appspot.com/o/src%2FAlbumCover.jpg?alt=media&token=e3882f83-1152-4c5c-95ba-576bb13dfa8e",
            genre: "",
            date: new Date(),
            tags: {
                a: false
            },
            tracks: {
                a: false
            },
            extra: ""
        })
        const albumData = await get(reference)
        await update(ref(db, "users/" + user.uid + "/albums"), {
            [albumData.key]: true
        })
        navigate("/albums/" + albumData.key + "/edit")
    };

    const fetchData = async () => {

        onValue(ref(db, "users/" + user.uid + "/public"), (snapshot) => {
            setPublic(snapshot.val())
        })
        onValue(ref(db, "users/" + user.uid + "/description"), (snapshot) => {
            setDescription(snapshot.val())
            document.querySelector("#desccount").textContent = numberWithCommas(snapshot.val().length) + " / 15,000"
        })
        const snapshot = await get(ref(db,"users/" + user.uid))

        setBackColor(snapshot.child("image_url").val())

        document.querySelector("#profileimg").src = snapshot.child("image_url").val()
        document.querySelector("#imagepreview").src = snapshot.child("image_url").val()
        document.querySelector("#usernametext").textContent = snapshot.child("username").val()
        document.querySelector("#changeusername").value = snapshot.child("username").val()
        document.querySelector("#followerstext").textContent = numberWithCommas(Object.keys(snapshot.child("followers").val()).length - 1) + " follower/s"
    };

    useEffect( () => {
        if (user) {
            fetchData();
        }
    },[user]);

    if (!user) {
        return (<main><Loading /></main>)
    };
  return (
    <div className="profile">
        <div id="overlay" style={{visibility: opened ? "visible": "hidden"}} onClick={openEditFrame}></div>

        <div id="editframe" style={{display: opened ? "flex": "none"}}>
            <div id="innereditframe">
                <button id="profileeditclose" onClick={openEditFrame}>x</button>
                <form className="editform" onSubmit={saveProfile}>
                    <div className="form">
                        <input id="changeusername" type="text" name="username" autoComplete="off" required></input>
                        <label htmlFor="text" className="label-name">
                            <span className="content-name">Username</span>
                        </label>
                    </div>
                    <img id="imagepreview" src={UserImg} alt=""></img>
                    <input id="changeimage" type="file" name="image" accept="image/*" onChange={setPreviewImg}></input>
                    <label id="inputimg" htmlFor="changeimage">
                        <img src={UploadImg} alt=""></img>
                    </label>
                    <button type="submit">Save</button>
                </form>
            </div>
        </div>

        <main >
            <div id="profilebox">
                <img id="profileimg" src={UserImg} alt=""></img>
                <div id="profilebox1">
                    <p id="usernametext">Username</p>
                    <p id="followerstext">100,000 follower/s</p>
                </div>
                <img id="editbutton" src={EditImg} alt="" onClick={openEditFrame}></img>
                <div id="profilebox2">
                    <p id="publictext">Public</p>
                    <label className="switch">
                        <input id="publicswitch" type="checkbox" onChange={handlePublicChange} checked={publicAccount}></input>
                        <span className="slider round"></span>
                    </label>
                </div>
            </div>
            <hr className="hr2"></hr>
            <p className="title">Links</p>
            <input type="text" placeholder="Name" id="linknameinput"></input>
            <input type="text" placeholder="Link" id="linkinput"></input>
            <button className="titlebtn" id="addlink" onClick={addLink}>+ Add</button>
            <div id="linklist">
                <LinksList id={user.uid} />
            </div>
            <br></br>
            <p className="title">Description</p>
            <button id="savedesc" className="titlebtn" onClick={saveDesc}>Save</button>
            <textarea name="description" id="description" placeholder="Type here..." maxLength="15000" onChange={handleDescChange} value={desc}></textarea>
            <p id="desccount">0 / 15000</p>
            <p className="title">Albums</p>
            <button id="newalbum" className="titlebtn" onClick={newAlbum}>+ New</button>
            <img id="spanalbums" className="titlebtn spanbtn" src={ArrowImg} onClick={(e) => span(e,".albumsList")}></img>
            <UserAlbumsList user={user} />
        </main>
    </div>
  )
}
