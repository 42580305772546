import React, { createElement, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { Loading } from './Loading';
import { get, onValue, ref, remove, set, update } from 'firebase/database';
import { db } from '../firebase';
import NotFound from './404';
import { setBackColor, toMinutesAndSeconds, toTime } from '../module';
import { Howl } from 'howler';
import Track from './track';
import TagsList from './tagsList';
import Heart_Filled from "../Images/Heart_Filled.png"
import Heart_Unfilled from "../Images/Heart_Unfilled.png"
import addNotification from 'react-push-notification';
import AddImg from "../Images/Add.png"
import AlbumCoverImg from "../Images/AlbumCover.jpg"
import ShareImg from "../Images/Share.png"
import Share from './Share';

export default function PlayAlbum({user}) {
    const {id} = useParams();
    const [data, setData] = useState();
    const [favourite, setFavourite] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [lists, setLists] = useState([]);
    const [sharing, setSharing] = useState(false);

    async function showLists(e) {
        e.stopPropagation();
        const listKeys = Object.keys((await get(ref(db, "users/" + user.uid + "/lists"))).val()).filter(a => a != "a")
        const data = []
        const tracks = await get(ref(db,"tracks"))
        const tracksKeys = []
        const albums = await get(ref(db,"albums"))
        const albumsKeys = []
        const users = await get(ref(db,"users"))
        const usersKeys = []
        users.forEach((u) => {
            if (u.key != "a"){
            const snap = u.val()
            snap["id"] = u.key
            snap.tracks = Object.keys(snap.albums).length > 1? Object.values(Object.keys(snap.albums).filter((i) => i != "a").map((albumId) => {
                const tracklist = Object.keys(albums.child(albumId).child("tracks").val()).map((trackId) => {return tracks.child(trackId).child("name").val()})
                return albumId
            })): null
            snap.plays = 0
            snap.albums = Object.keys(snap.albums).length > 1? Object.values(Object.keys(snap.albums).map((albumId) => {return albums.child(albumId).child("title").val()})): null
            usersKeys.push(snap)
            }
        })
        albums.forEach((album) => {
            if (album.key != "a"){
            const snap = album.val()
            snap["id"] = album.key
            snap["plays"] = 0
            album.child("tracks").forEach((track) => {snap.plays += tracks.child(track.key).child("plays").val()})
            snap.tracks = Object.values(Object.keys(snap.tracks).map((trackId) => {return tracks.child(trackId).child("name").val()}))
            snap.tags = Object.keys(snap.tags)
            snap["artist"] =  usersKeys.find(function (value) {
                let found = false;
                users.child(value.id).child("albums").forEach((key) => {if (key.key == snap.id){found = true};})
                return found;
            })
            usersKeys.find(a => a.id == snap.artist.id).plays += snap.plays
            albumsKeys.push(snap)
            }
        })
        tracks.forEach((track) => {
            if (track.key != "a"){
            const snap = track.val()
            snap["id"] = track.key
            snap["album"] =  albumsKeys.find(function (value) {
                let found = false;
                albums.child(value.id).child("tracks").forEach((key) => {if (key.key == track.key){found = true};})
                return found;
            })
            snap["artist"] =  usersKeys.find(function (value) {
                let found = false;
                users.child(value.id).child("albums").forEach((key) => {if (key.key == snap.album.id){found = true};})
                return found;
            })
            snap["list"] = {
                name: snap.artist.username,
                type: "users",
                id: snap.artist.id
            }
            tracksKeys.push(snap)
            }
        })
        for (let i in listKeys) {
          const snap = (await get(ref(db, "lists/" + listKeys[i]))).val()
          snap.id = listKeys[i]
          snap.cover_url = (Object.keys(snap.tracks).filter(a => a != "a").length == 0? AlbumCoverImg:
          tracksKeys.find(a => a.id == Object.keys(snap.tracks).filter(a => a != "a")[Math.floor(Math.random()*(Object.keys(snap.tracks).filter(a => a != "a").length-1))] )?.album.cover_url || AlbumCoverImg)
          
          data.push(snap)
        }
        setLists(data); 
      }

    async function fetchData() {
        const album = await get(ref(db,"albums/" + id))
        if (album.exists()) {
            setBackColor(album.child("cover_url").val());

            const users = await get(ref(db,"users"))
            const usersKeys = []
            const tracks = await (await get(ref(db,"tracks"))).val()

            users.forEach((user) => {
                if (user.key != "a"){
                const a = user.val()
                a["id"] = user.key
                usersKeys.push(a)
                }
            })

            const snap = album.val();
            snap.id = album.key;

            snap["artist"] = usersKeys.find(function (value) {
                let found = false;
                users.child(value.id).child("albums").forEach((key) => {if (key.key == id){found = true};})
                return found;
            })

            const albumTracks = []

            for (let track in snap.tracks) {
                if (track != "a") {
                    const trackData = tracks[track]
                    trackData.album = {... snap}
                    trackData.id = track;
                    trackData.artist = snap.artist;
                    trackData["list"] = {
                            name: snap.title,
                            type: "albums",
                            id: id
                        }
                    albumTracks.push(trackData);
                }
            }

            snap.tracks = albumTracks
            snap["duration"] = 0
            function loadSong(index) {
                const song = new Howl({
                    src: [albumTracks[index].source],
                    format: "mpeg",
                    html5: true,
                    onload: () => {
                        snap["duration"] += song.duration();
                        if (albumTracks[index + 1]) {loadSong(index + 1)}
                        else {setData(snap); navigate(location, { state: { loaded: true } })}
                    }
                })
            }
            if (albumTracks.length > 0) {
               loadSong(0) 
            }else{
                setData(snap); navigate(location, { state: { loaded: true } })
            } 
        }
        else {
            return (<NotFound />);
        }
    }

    useEffect(() => {
        if (user) {
            fetchData();
            onValue(ref(db,"users/" + user.uid + "/favourite"),(snap) => {
                if (Object.keys(snap.val()).find(a => a == id)) {
                    setFavourite(true)
                } else {
                    setFavourite(false)
                }
            });
        }
    },[user])

    if (!data || !user) {
        return (<main><Loading /></main>)
    }

  return (
    <main className="play-album">
        <Share opened={sharing} open={setSharing} type={"album"} id={id}></Share>
        <div id="albumTitle">
            <img src={data.cover_url}></img>
            <div>
                <p className='title'>{data.title}</p>
                <Link onMouseEnter={(e) => e.target.style.textDecoration = "underline"} onMouseLeave={(e) => e.target.style.textDecoration = "none"} style={{display: "inline", margin: "0px", padding: "0px", color: "var(--third-color)", textDecoration: "none", pointerEvents: "auto", fontSize: "20px"}} to={"/artists/" + data.artist.id} className={"link"}>{data.artist.username}</Link>
                <p style={{display: "inline", margin: "0px", color: "var(--third-color)", fontSize: "20px"}}>{data.date ? " · " + (new Date(data.date.replace(/-/g, '\/'))).toLocaleDateString("en-GB", {day: "numeric", month: "long", year: "numeric"}): ""}</p>
            </div>
        </div>
        <img src={favourite? Heart_Filled: Heart_Unfilled} style={{position: "absolute", right: "20px", top: "70px", width: "40px"}} onClick={() => {
            if (favourite) {
                remove(ref(db,"users/" + user.uid + "/favourite/" + id))
            } else {
                update(ref(db,"users/" + user.uid + "/favourite"), {
                    [id]: true
                })
            }
        }}></img>
        <p style={{position: "absolute", right: "20px", top: "115px", color: "var(--third-color)"}}>{data.tracks.length + " songs · " + toTime(data.duration)}</p>
        <hr className="hr2" style={{marginBottom: "10px"}}/>
        <div style={{display: "flex", justifyContent: "start", width: "100%", marginBottom: "10px", gap: "30px", paddingInline: "10px"}}>
            <div style={{display: "flex", alignItems: "center", gap: "10px"}} onClick={(e) => {showLists(e)}}>
              <img src={AddImg} style={{width: "30px", height: "30px"}}></img>
              <p style={{color: "var(--third-color)"}}>Add to a list</p>
            </div>
            <div style={{display: "flex", alignItems: "center", gap: "10px"}} onClick={(e) => {setSharing(true)}}>
              <img src={ShareImg} style={{width: "30px", height: "30px"}}></img>
              <p style={{color: "var(--third-color)"}}>Share</p>
            </div>
        </div>
        <div style={{width: data.extra == ""? "100%":"70%", float: "left"}}>
            {data.tracks.map((track, index) => (
                <div key={track.id} onClick={() => navigate(location, { state: { play: data.tracks, index: index } })}>
                    <Track index={index + 1} track={track} user={user}></Track>
                </div>
            ))}
            <div style={{width: "100%", display: "flex", justifyContent: "center", marginTop: "50px"}}>
                {Object.keys(data.tags).filter(item => item != "a").map(snapshot => (
                    <p key={snapshot} style={{color: "var(--third-color)", display: "inline-block", margin: "10px"}}>
                        {"#" + snapshot}
                    </p>
                ))}
            </div>
        </div>
        <p style={{color: "var(--third-color)", textAlign: "justify", fontSize: "14px", width: data.extra == ""? "0": "28%", float: "right", whiteSpace: "preserve", marginRight: "2%"}}>{data.extra}</p>
        
        {lists.length > 0? 
          <div onClick={(e) => {
            e.stopPropagation();
            setLists([]);
          }} style={{position: "fixed", zIndex: "5", top: "0", left: "0", width: "100%", height: "100%", display: "flex", justifyContent: "end", alignItems: "end"}}>
            <div style={{width: "350px", height: "100%", display: "flex", flexDirection: "column", backgroundColor: "var(--second-color)", borderRadius: "20px"}}>
              <p style={{position: "absolute", right: "320px", top: "10px", margin: "0", fontSize: "20px", color: "var(--third-color)"}}>x</p>
              <p style={{color: "white", fontSize: "30px", marginTop: "100px", marginLeft: "15px", marginBottom: "10px"}}>Lists</p>
              <hr style={{width: "100%", marginTop: "0"}}></hr>
              {lists.map(list => (
                <div className={"track"} key={list.id} onClick={async (e) => {
                  e.stopPropagation();
                  for (let i in data.tracks) {
                    await update(ref(db,"lists/" + list.id + "/tracks"),{
                        [data.tracks[i].id]: true
                    });
                  }
                  setLists([]);
                }}>
                    <div className="container">
                        <img id="trackcover" src={list.cover_url} alt="" style={{marginLeft: "5px"}}></img>
                        <div style={{marginLeft: "10px", width: "30%"}}>
                          <p className='trackdisplayname' style={{margin: "0px", color: "white", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>{list.title}</p>
                        </div>
                    </div>
                </div>
              ))}
            </div>
          </div>:
          <div></div>
        }

    </main>
  )
}
