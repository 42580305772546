import React from 'react'
import { useNavigate } from 'react-router-dom';

export default function Artist({artist}) {

  const navigate = useNavigate();
  return (
    <div className="artist">
        <div className="artistcover" style={{backgroundImage: `url(${artist.image_url})`}} >
            <div className="editArtist" alt="" onClick={() => navigate("/artists/" + artist.id)}></div>
        </div>
        <p className='artistName'>{artist.username}</p>
    </div>
  )
}
