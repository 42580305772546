import React, { useEffect, useState } from "react";
import ExploreImg from "../Images/Search.png"
import { useNavigate } from "react-router-dom";
import { genres, getNumberOfDays, setBackColor, span } from "../module";
import { get, ref } from "firebase/database";
import { db } from "../firebase";
import Album from "./album";
import ArrowImg from "../Images/Arrow.png"

export default function Explore() {
    const navigate = useNavigate();

    const [albumData, setAlbums] = useState([]);
    const [genresSelected, setGenres] = useState([]);

    function handleGenres(genre) {
        if (genresSelected.includes(genre)) {
            setGenres(genresSelected.filter(e => e !== genre));
        } else {
            setGenres([...genresSelected, genre]);
        }
    }

    async function fetchData() {
        const tracks = await get(ref(db,"tracks"))
        const users = await get(ref(db,"users"))
        const albums = await get(ref(db,"albums"));
        const usersKeys = []
        users.forEach((user) => {
            if (user.key != "a"){
            const snap = user.val()
            snap["id"] = user.key
            snap.albums = Object.keys(snap.albums).length > 1? Object.values(Object.keys(snap.albums).map((albumId) => {return albums.child(albumId).child("title").val()})): null
            usersKeys.push(snap)
            }
        })

        const data = [];
        albums.forEach((album) => {
            if (album.key != "a" && (genresSelected.length == 0 || genresSelected.includes(album.child("genre").val()))){
            const snap = album.val()
            snap["id"] = album.key
            let weekplays = 0
            for (let u in usersKeys) {
                for (let play in usersKeys[u].record) {
                    if (play != "a" && getNumberOfDays(usersKeys[u].record[play].date,(new Date()).toString()) <= 7 && snap.tracks[usersKeys[u].record[play].id]) {
                        weekplays += 1
                    }
                }
            }
            snap.weekplays = weekplays
            snap.tracks = Object.values(Object.keys(snap.tracks).map((trackId) => {return tracks.child(trackId).child("name").val()}))
            snap.plays = 0
            album.child("tracks").forEach((track) => {snap.plays += tracks.child(track.key).child("plays").val()})
            snap.tags = Object.keys(snap.tags)
            snap["artist"] =  usersKeys.find(function (value) {
                let found = false;
                users.child(value.id).child("albums").forEach((key) => {if (key.key == snap.id){found = true};})
                return found;
            })
            data.push(snap);
            }
        })
        setAlbums(data);
    }

    useEffect(() => {
        setBackColor();
        fetchData();
    },[genresSelected])

    return (
        <div className="Explore">
            <div className='search'>
                <img src={ExploreImg}></img>
                <input type='search' placeholder='Search' onFocus={() => navigate("/search")}></input>
            </div>
            <main>
                <p className="title">Genres</p>
                <div style={{display: "flex", paddingInline: "25px", paddingBottom: "5px", gap: "10px 20px", flexWrap: "wrap"}}>
                    {genres.map((genre) => (
                        <p style={{transition: ".1s", color: genresSelected.includes(genre) ? "var(--selected)": "var(--third-color)", cursor: "pointer", whiteSpace: "nowrap", margin: "0"}} key={genre}
                            onMouseEnter={(e) => {e.target.style.color = "white"}}
                            onMouseLeave={(e) => {e.target.style.color = genresSelected.includes(genre) ? "var(--selected)": "var(--third-color)"}}
                            onClick={() => {handleGenres(genre)}}
                        >{genre}</p>
                    ))}
                </div>
                <br></br>
                <p className="title">New</p>
                <img id="spanalbums" className="titlebtn spanbtn" src={ArrowImg} onClick={(e) => span(e,".albumsList.new")}></img>
                <hr style={{marginTop: "5px"}}></hr>
                <div className="albumsList new">
                    {albumData.filter(a => a.artist.public == true).sort(function(a,b){
                        return new Date(b.date) - new Date(a.date);
                        }).slice(0,10).map((album) => (
                        <Album album = {album} key={album.id} />
                    ))}
                </div>
                <p className="title">Trending</p>
                <img id="spanalbums" className="titlebtn spanbtn" src={ArrowImg} onClick={(e) => span(e,".albumsList.trending")}></img>
                <hr style={{marginTop: "5px"}}></hr>
                <div className="albumsList trending">
                    {albumData.filter(a => a.artist.public == true).sort(function(a,b){
                        return b.weekplays - a.weekplays;
                        }).slice(0,10).map((album) => (
                        <Album album = {album} key={album.id} />
                    ))}
                </div>
            </main>
        </div>
    )
}