import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import './App.css';

import { onAuthStateChanged } from 'firebase/auth';

import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

import Home from './components/Home';
import Explore from './components/Explore';
import Menu from './components/menu';
import Login from './components/login';
import Register from './components/register';
import Profile from './components/Profile';
import { auth } from './firebase';
import NotFound from './components/404';
import EditAlbum from './components/edit-album';
import PrivateRoute from './components/PrivateRoute';
import About from './components/About';
import Search from './components/Search';
import PlayAlbum from './components/playAlbum';
import Library from './components/Library';
import { Notifications } from 'react-push-notification';
import EditList from './components/EditList';
import PlayTrack from './components/PlayTrack';

function App() {
  const [user, setUser] = useState(undefined)

  useEffect(() => {
    
    onAuthStateChanged(auth, (_user) => {
      setUser(_user)
    })
  },[])

  return (
    <Router>
      <div id="snackbar">Some text some message..</div>
      <div id='backColor' style={{position: "fixed", left: "0", top: "0", right: "0", bottom: "0", transition: ".5s"}}></div>
      <Menu user={user} />
      <Notifications />
      <Routes>
        <Route path="/" element={<Navigate replace to="/home" />} />
        <Route path="/home" element={<Home user={user}/>} />
        <Route path="/explore" element={<Explore />} />
        <Route path="/library" element={<PrivateRoute><Library user={user} /></PrivateRoute>} />

        <Route path="/profile" element={<PrivateRoute><Profile user={user} /></PrivateRoute>} />
        <Route path="/albums/:id/edit" element={<EditAlbum user={user} />} />
        <Route path="/lists/:id" element={<EditList user={user} />} />
        <Route path="/artists/:id" element={<PrivateRoute><About user={user}/></PrivateRoute>} />
        <Route path="/albums/:id" element={<PrivateRoute><PlayAlbum user={user}/></PrivateRoute>} />
        <Route path="/search/:searchQuery" element={<Search user={user}/>} />
        <Route path="/search" element={<Search user={user}/>} />

        <Route path="/track/:id" element={<PlayTrack></PlayTrack>} />
        
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App
