import { ref, remove } from 'firebase/database';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase';

export default function Notify({img, text, path, id, user, checked}) {
    const navigate = useNavigate();

    function formatText(text) {
        const parts = text.split(/(\[.*?\])/g);
        return parts.map((part, index) => {
            if (part.startsWith('[') && part.endsWith(']')) {
                return <b key={index}>{part.slice(1, -1)}</b>;
            }
            return part;
        });
    }

    return (
        <div style={{display: "flex", gap: "10px", alignItems: "center", width: "100%", overflow: "hidden", marginBlock: "5px"}}>
            <div style={{display: "flex", gap: "10px", alignItems: "center", paddingLeft: "5px", transition: ".1s", borderRadius: "10px"}} onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = "var(--third-color)";
            }} onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = "transparent";
            }} onClick={() => navigate(path)}>
                <img style={{width: "35px", height: "35px", borderRadius: "50%"}} src={img} alt="Notifications"></img>
                <p className='notification-text' style={{margin: "0", marginBlock: "5px", color: "white", fontSize: "14px"}}>
                    {formatText(text)}
                </p>
            </div>
            <button style={{margin: "0", color: "white", backgroundColor: "transparent", border: "none"}} onClick={() => {
                console.log(id);
                remove(ref(db, "users/" + user.uid + "/notifications/" + id));
            }}><b>x</b></button>
            <p style={{display: checked? "none": "", position: "absolute", fontSize: "15px", fontWeight: "bold", translate: "-5px 0px", color: "red"}}>!</p>
        </div>
    );
}
