import React from 'react'
import PlayImg from "../Images/Play.png"
import { Link, useNavigate } from 'react-router-dom'

export default function Album({album}) {

    const navigate = useNavigate();

  return (
    <div className="album">
        <div className="albumcover" style={{backgroundImage: `url(${album.cover_url})`}} >
            <div className="editAlbum" alt="" onClick={() => navigate("/albums/" + album.id)}></div>
        </div>
        <p className='albumTitle'>{album.title}</p>
        <div style={{display: 'flex', gap: "5px"}}>
        <Link onMouseEnter={(e) => e.target.style.textDecoration = "underline"} onMouseLeave={(e) => e.target.style.textDecoration = "none"} style={{flexGrow: "0", flexShrink: "1", flexBasis: "fit-content", margin: "0px", padding: "0px", color: "var(--third-color)", textDecoration: "none", pointerEvents: "auto", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}} to={"/artists/" + album.artist.id} className={"link"}>{album.artist.username}</Link>
        <p style={{flexGrow: "0", flexShrink: "1", flexBasis: "fit-content", margin: "0px", padding: "0px", color: "var(--third-color)", textDecoration: "none", pointerEvents: "auto", whiteSpace: "nowrap"}}>{album.date && window.matchMedia("(min-width: 750px)").matches ? " · " + (new Date(album.date.replace(/-/g, '\/'))).getFullYear(): ""}</p>
        </div>
  </div>
  )
}
