import React, { useEffect, useState } from 'react';
import { ref, getDatabase, get, remove, onValue } from 'firebase/database';
import { useList } from 'react-firebase-hooks/database';
import { auth, db } from '../firebase';

export default function LinksList({id}) {
    const [data, setData] = useState([])

    useEffect(() => {
        onValue(ref(db, "users/" + id + "/links"), (snap) => {
            if (snap.exists()) {
                const dataList = []
                snap.forEach((child) => {
                    dataList.push(child)
                })
                setData(dataList)
            }
        })
    },[])

  return (
    <div>
        {data.filter(item => item.key !== "a").map(snapshot => (
            <div className="link" key={snapshot.val()}>
                <p id="linkname">{snapshot.key + ":"}</p>{" "}
                <a href={snapshot.val()} id="linkhref">{snapshot.val()}</a>
                <button id="deletelink" onClick={() => remove(ref(db,"users/" + id + "/links/" + snapshot.key))}>x</button>
            </div>
        ))}
    </div>
  );
};