import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import EditImg from "../Images/Edit.png";
import DeleteImg from "../Images/Delete.png";
import { get, onValue, push, ref, remove, set, update } from 'firebase/database';
import { db, storage } from '../firebase';
import Rejected from './Rejected';
import { genres, setBackColor, Toast } from '../module';
import TagsList from './tagsList';
import { deleteObject, getDownloadURL, getMetadata, ref as storageRef, uploadBytes } from 'firebase/storage';
import TracksEditList from './tracksEditList';
import { Loading } from './Loading';

export default function EditAlbum({user}) {
    const {id} = useParams();
    const [permission, setPermission] = useState();
    const navigate = useNavigate();

    function getPermission(userData,id) {
        let hasPermission = false;
        userData.child("albums").forEach(album => {
            if (album.key == id) {
                hasPermission = true;
            };
        });
        return hasPermission;
    };

    const fetchData = async () => {
        const userData = await get(ref(db,"users/" + user.uid));
        const p = getPermission(userData,id);
        setPermission(p);
        if (p) {
            const albumData = await get(ref(db, "albums/" + id));
            setBackColor(albumData.child("cover_url").val())
            document.querySelector("#titleinput").value = albumData.child("title").val()
            document.querySelector("#albumcover").style.backgroundImage = "url(" + albumData.child("cover_url").val() + ")"
            document.querySelector("#extra").value = albumData.child("extra").val()
            document.querySelector("#dateinput").value = albumData.child("date").val()
            document.querySelector("#genre").value = albumData.child("genre").val()
        };
    };

    async function changeImg() {
        var file = document.querySelector("#changeimage").files[0]
        await uploadBytes(storageRef(storage, "img/preview/" + user.uid), file).then( async (snapshot) => {
            await getDownloadURL(storageRef(storage, "img/preview/" + user.uid)).then((url) => {
                document.querySelector("#albumcover").style.backgroundImage = "url(" + url + ")"
            })
        });
    }

    async function saveAlbum() {
        var file = document.querySelector("#changeimage").files[0]
        if (file != null) {
            try {
                await deleteObject(storageRef(storage, "img/preview/" + user.uid))
            } catch(err) {
                console.log("Does not exist")
            }
            await uploadBytes(storageRef(storage, "img/cover/" + id), file).then( async () => {
                await getDownloadURL(storageRef(storage, "img/cover/" + id)).then( async (url) => {
                    await update(ref(db, "albums/" + id), {
                        cover_url: url
                    })
                })
            });
        };
        await update(ref(db, "albums/" + id), {
            title: document.querySelector("#titleinput").value,
            genre: document.querySelector("#genre").value,
            date: document.querySelector("#dateinput").value,
            extra: document.querySelector("#extra").value
        });

        const followers = Object.keys((await get(ref(db,"users/" + user.uid + "/followers"))).val()).filter(a => a != "a")
        const not = {
            img: (await get(ref(db,"albums/" + id + "/cover_url"))).val(),
            text: "[" + (await get(ref(db,"users/" + user.uid + "/username"))).val() + "] has published [" + (await get(ref(db,"albums/" + id + "/title"))).val() + "].",
            checked: false,
            path: "albums/" + id
        }
        for (let i in followers) {
            push(ref(db,"users/" + followers[i] + "/notifications/"), not)
        }

        Toast("Saved", 3000)
    }

    async function deleteAlbum() {
        try {
            await deleteObject(storageRef(storage,"img/cover/" + id))
        } catch(err) {
            console.log("Source does not exist")
        }

        const tracks = await get(ref(db,"albums/" + id + "/tracks"))
        tracks.forEach((tKey) => {
            if (tKey.key != "a") {
                try {
                    deleteObject(storageRef(storage, "tracks/" + id + "/" + tKey.key))
                }
                catch (err) {
                    console.log("Source does not exist")
                }
                remove(ref(db,"tracks/" + tKey.key))
            }
        })

        await remove(ref(db, "albums/" + id))
        await remove(ref(db, "users/" + user.uid + "/albums/" + id))
        navigate("/profile")
    }

    async function addTag() {
        const tagname = document.querySelector("#tagname").value
        if (tagname != "") {
            document.querySelector("#tagname").value = ""
            await update(ref(db,"albums/" + id + "/tags"), {
                [tagname]: true
            })
        }
    };

    async function newTrack() {
        const reference = await push(ref(db, "tracks"))
        await set(reference, {
            name: "New song",
            source: "",
            plays: 0
        })
        const trackData = await get(reference)
        const tracksSnap = await get(ref(db, "albums/" + id + "/tracks"))
        await update(ref(db, "albums/" + id + "/tracks"), {
            [trackData.key]: Object.keys(tracksSnap.val()).length
        })
    };

    useEffect( () => {
        if (user) {
            fetchData();
        }
    },[user]);

    if (!permission) {
        return(
            <main>
                <Loading />
            </main>
        );
    };

    if (permission == false) {
        return(
            <Rejected />
        );
    };

  return (
    <main className="edit-album">
        <div id="profilebox">
            <div id="albumpreview" className="album">
                <div id="albumcover" className="albumcover">
                    <input id="changeimage" type="file" name="image" accept="image/*" onChange={changeImg}></input>
                    <label htmlFor="changeimage">
                        <img className="editAlbum" src={EditImg} alt="" style={{margin: "0px"}}></img>
                    </label>
                </div>
            </div>
            <div id="profilebox1">
                <div id="titleform" className="form">
                    <input id="titleinput" type="text" name="title" autoComplete="off" required></input>
                    <label htmlFor="text" className="label-name" >
                        <span className="content-name">Title</span>
                    </label>
                </div>
            </div>
            <div id="profilebox2">
                <button id="savealbum" onClick={saveAlbum}>Save & Publish</button>
                <button id="deletealbum" onClick={deleteAlbum}>
                    <img src={DeleteImg} alt=""></img>
                </button>
            </div>
        </div>
        <hr className="hr2"/>
        <div className="leftframe">
            <p className="title">Tracks</p>
            <button id="newtrack" className="titlebtn" onClick={newTrack}>+ New</button>
            <div id="tracklist">
                <TracksEditList id={id} />
            </div>
        </div>
        <div className="rightframe">
            <p className="title">Genre</p>
            <select name="Genre" id="genre" required>
                {genres.map(genre => (
                    <option key={genre} value={genre}>{genre}</option>
                ))}
            </select>
            <br/>
            <p className="title">Date</p>
            <input type="date" id="dateinput"></input>
            <br/>
            <p className="title">Tags</p>
            <input type="text" id="tagname" placeholder="#"></input>
            <button className="titlebtn" id="addtag" onClick={addTag}>+ Add</button>
            <div id="taglist">
                <TagsList id={id} />
            </div>
            <br/>
            <p className="title">Extra information</p>
            <textarea name="Extra" id="extra"></textarea>
        </div>
    </main>
  );
};
