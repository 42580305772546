import React, { useEffect, useState } from 'react';
import { ref, getDatabase, get, onValue } from 'firebase/database';
import { useList, useListKeys, useObject } from 'react-firebase-hooks/database';
import { auth, db } from '../firebase';
import { Navigate, useNavigate } from 'react-router-dom';

import EditImg from "../Images/Edit.png"

export default function UserAlbumsList({user}) {
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const dbRef = ref(db, 'albums');
      onValue(ref(db, "users/" + user.uid + "/albums"), async (albumkeys) => {
        const snapshot = await get(dbRef);

        if (snapshot.exists()) {
          const dataList = [];
          albumkeys.forEach(album => {
            dataList.push(snapshot.child(album.key));
          });
          setData(dataList);
        } else {
          console.log("No data available");
        }
      })
    };

    fetchData();
  }, []);

  return (
    <div className='albumsList'>
        {data.filter(item => item.key !== "a").map(snapshot => (
            <div className="album" key={snapshot.key}>
                <div className="albumcover" style={{backgroundImage: `url(${snapshot.child("cover_url").val()})`}} >
                    <img className="editAlbum" src={EditImg} alt="" onClick={() => navigate("/albums/" + snapshot.key + "/edit")}></img>
                </div>
                <p className='albumTitle'>{snapshot.child("title").val()}</p>
                <br/>
            </div>
        ))}
    </div>
  );
};