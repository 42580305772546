import { onAuthStateChanged } from "firebase/auth"
import { auth } from "./firebase"
import { useEffect, useState } from "react"
import { FastAverageColor } from "fast-average-color"

export function Toast (text, duration) {
    var x = document.querySelector("#snackbar")
    x.className = "show"
    x.textContent = text
    console.log(text)
    setTimeout(function(){ 
        x.className = x.className.replace("show", "")
    }, duration)
}

export function span(event,frame) {
    const spanbtn = event.target
    if (document.querySelector(frame).style.maxHeight == "unset") {
        if (window.matchMedia("(min-width: 750px)").matches) {
            document.querySelector(frame).style.maxHeight = "225px"
        }
        else {
            document.querySelector(frame).style.maxHeight = "120px"
        }
        spanbtn.style.transform = "rotate(180deg)"    
    }
    else {
        document.querySelector(frame).style.maxHeight = "unset"
        spanbtn.style.transform = "rotate(90deg)"
    }
}

export function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function toMinutesAndSeconds(totalSeconds) {

    totalSeconds = Math.trunc(totalSeconds)

const minutes = Math.floor(totalSeconds / 60);

const seconds = totalSeconds % 60;

function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}

return `${minutes}:${padTo2Digits(seconds)}`;
}

export function toTime(totalSeconds) {

    totalSeconds = Math.trunc(totalSeconds)

const hours = Math.floor(totalSeconds / 3600);

const minutes = Math.floor((totalSeconds % 3600) / 60);

const seconds = totalSeconds % 60;

function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}

return hours > 0 ? `${hours} h ${minutes} min`: `${minutes} min`;
}

export function setBackColor(img_url) {
    if (img_url) {
        const fac = new FastAverageColor();

    fac.getColorAsync(img_url, {algorithm: "sqrt"})
        .then(color => {
            document.querySelector("#backColor").style.opacity = 0
            setTimeout(function() {
            document.querySelector("#backColor").style.backgroundImage = color.isDark ? "linear-gradient(to top, var(--main-color) 50%, rgba(" + color.value.slice(0,3) + ",0.6))": "linear-gradient(to top, var(--main-color) 50%, rgba(" + color.value.slice(0,3) + ",0.3))";
            document.querySelector("#backColor").style.opacity = 1
            },500)
        })
        .catch(e => {
            console.log(e);
        });
    } else {
        document.querySelector("#backColor").style.opacity = 0
            setTimeout(function() {
            document.querySelector("#backColor").style.backgroundImage = "linear-gradient(to top, var(--main-color) 50%, var(--back-color))";
            document.querySelector("#backColor").style.opacity = 1
            },500)
    }
}

export function setBackByColor(color) {
    document.querySelector("#backColor").style.opacity = 0
    setTimeout(function() {
        document.querySelector("#backColor").style.backgroundImage = "linear-gradient(to top, var(--main-color) 50%, " + color + ")";
        document.querySelector("#backColor").style.opacity = 1
    },500)
}

export function getNumberOfDays(start, end) {
    const date1 = new Date(start);
    const date2 = new Date(end);

    // One day in milliseconds
    const oneDay = 1000 * 60 * 60 * 24;

    // Calculating the time difference between two dates
    const diffInTime = date2.getTime() - date1.getTime();

    // Calculating the no. of days between two dates
    const diffInDays = Math.round(diffInTime / oneDay);

    return diffInDays;
}

export const genres = [
    "African",
    "Arabic",
    "Blues",
    "Indian",
    "Classical",
    "Country",
    "Electronic",
    "Flamenco",
    "Folk",
    "Hip Hop",
    "Indie",
    "Jazz",
    "Latin",
    "Metal",
    "Musicals & Sound Tracks",
    "Pop",
    "R&B & Soul",
    "Reggae",
    "Rock"
]