import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyA7bK6MIHMWvF0ppha3DeBz_Xb3N8XjExw",
  authDomain: "tdrfirebase-cef0a.firebaseapp.com",
  databaseURL: "https://tdrfirebase-cef0a-default-rtdb.firebaseio.com",
  projectId: "tdrfirebase-cef0a",
  storageBucket: "tdrfirebase-cef0a.appspot.com",
  messagingSenderId: "132232446012",
  appId: "1:132232446012:web:43bccdddb676dfcfe38339",
  measurementId: "G-J3P8B4LY6B"
};

export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const db = getDatabase(app);
export const storage = getStorage(app);

