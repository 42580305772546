import React, { useEffect, useState } from "react";
import ExploreImg from "../Images/Search.png"
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { get, ref } from "firebase/database";
import Fuse from "fuse.js";
import { db } from "../firebase";
import Track from "./track";
import Album from "./album";
import Artist from "./artist";
import { Loading } from "./Loading";
import { span, genres } from "../module";
import ArrowImg from "../Images/Arrow.png"
import List from "./list";

export default function Search({user}) {
    const navigate = useNavigate();
    const {searchQuery} = useParams();
    const [songs, setSongs] = useState([]);
    const [albums, setAlbums] = useState([]);
    const [artists, setArtists] = useState([]);
    const [lists, setLists] = useState([]);
    const [genresSelected, setGenres] = useState([]);
    const location = useLocation();

    function handleGenres(genre) {
        if (genresSelected.includes(genre)) {
            setGenres(genresSelected.filter(e => e !== genre));
        } else {
            setGenres([...genresSelected, genre]);
        }
    }

    async function fetchData() {
        if(searchQuery != undefined) {
            const songsData = []
            const albumsData = []
            const artistsData = []
            const listsData = []
            const tracks = await get(ref(db,"tracks"))
            const tracksKeys = []
            const albums = await get(ref(db,"albums"))
            const albumsKeys = []
            const users = await get(ref(db,"users"))
            const usersKeys = []
            const lists = await get(ref(db,"lists"))
            const listsKeys = []

            users.forEach((user) => {
                if (user.key != "a"){
                const snap = user.val()
                snap["id"] = user.key
                snap.tracks = Object.keys(snap.albums).length > 1? Object.values(Object.keys(snap.albums).filter((i) => i != "a").map((albumId) => {
                    const tracklist = Object.keys(albums.child(albumId).child("tracks").val()).map((trackId) => {return tracks.child(trackId).child("name").val()})
                    return albumId
                })): null
                snap.albums = Object.keys(snap.albums).length > 1? Object.values(Object.keys(snap.albums).map((albumId) => {return albums.child(albumId).child("title").val()})): null
                usersKeys.push(snap)
                }
            })
            albums.forEach((album) => {
                if (album.key != "a"){
                const snap = album.val()
                snap["id"] = album.key
                snap["plays"] = 0
                album.child("tracks").forEach((track) => {snap.plays += tracks.child(track.key).child("plays").val()})  
                snap.tracks = Object.values(Object.keys(snap.tracks).map((trackId) => {return tracks.child(trackId).child("name").val()}))
                snap.tags = Object.keys(snap.tags)
                snap["artist"] =  usersKeys.find(function (value) {
                    let found = false;
                    users.child(value.id).child("albums").forEach((key) => {if (key.key == snap.id){found = true};})
                    return found;
                })
                albumsKeys.push(snap)
                }
            })
            tracks.forEach((track) => {
                if (track.key != "a"){
                const snap = track.val()
                snap["id"] = track.key
                snap["album"] =  albumsKeys.find(function (value) {
                    let found = false;
                    albums.child(value.id).child("tracks").forEach((key) => {if (key.key == track.key){found = true};})
                    return found;
                })
                snap["artist"] =  usersKeys.find(function (value) {
                    let found = false;
                    users.child(value.id).child("albums").forEach((key) => {if (key.key == snap.album.id){found = true};})
                    return found;
                })
                snap["list"] = {
                    name: snap.artist.username,
                    type: "users",
                    id: snap.artist.id
                }
                tracksKeys.push(snap)
                }
            })

            lists.forEach((list) => {
                if (list.key != "a"){
                const snap = list.val()
                snap["id"] = list.key
                snap.tracks = Object.values(Object.keys(snap.tracks).map((trackId) => {return tracksKeys.find(a => a.id == trackId)}))
                snap["author"] =  usersKeys.find(function (value) {
                    let found = false;
                    users.child(value.id).child("lists").forEach((key) => {if (key.key == snap.id){found = true};})
                    return found;
                })
                listsKeys.push(snap)
                }
            })

            const songsfuse = new Fuse(tracksKeys,{
                includeScore: true,
                shouldSort: true,
                findAllMatches: true,
                keys: ["name", "album.title", "artist.username", "album.tags.", "album.extra", "album.genre"]
            })
            songsfuse.search(searchQuery).sort((a, b) => { 
                if (a.score === b.score) {
                    return b.item.plays - a.item.plays; 
                }
                return a.score - b.score; 
            }).forEach((item) => {
                if (item.item.artist.public == true) {
                    songsData.push(item.item);
                }
            })

            const albumsfuse = new Fuse(albumsKeys,{
                includeScore: true,
                shouldSort: true,
                findAllMatches: true,
                keys: ["title", "artist.username", "extra", "tags.", "tracks.", "genre"]
            })
            albumsfuse.search(searchQuery).sort((a, b) => {
                if (a.score === b.score) {
                    return b.item.plays - a.item.plays;
                }
                return a.score - b.score;
            }).forEach((item) => {
                if (item.item.artist.public == true) {
                    albumsData.push(item.item);
                }
            })

            const listsfuse = new Fuse(listsKeys,{
                includeScore: true,
                shouldSort: true,
                findAllMatches: true,
                keys: ["title", "author.username", "tracks."]
            })
            listsfuse.search(searchQuery).sort((a, b) => {
                return a.score - b.score;
            }).forEach((item) => {
                if (item.item.author.public == true) {
                    listsData.push(item.item);
                }
            })

            const artistsfuse = new Fuse(usersKeys,{
                includeScore: true,
                shouldSort: true,
                findAllMatches: true,
                keys: ["username", "albums.", "description", "tracks."]
            })
            artistsfuse.search(searchQuery).sort((a, b) => {
                if (a.score === b.score) {
                    return Object.keys(b.item.followers).length - Object.keys(a.item.followers).length; // Sort by 'plays' in descending order
                }
                return a.score - b.score; // Sort by score in ascending order
            }).forEach((item) => {
                if (item.item.public == true) {
                    artistsData.push(item.item);
                }
            })
            
            setSongs(songsData);
            setAlbums(albumsData);
            setArtists(artistsData);
            setLists(listsData)
            
        }
    }

    useEffect(() => {
        fetchData();
    },[location])

    return (
        <div className="Search">
            <div className='search'>
                <img src={ExploreImg}></img>
                <input type='search' autoFocus placeholder='Search' onChange={(e) => navigate("/search/" + e.target.value)}></input>
            </div>
            <main style={{visibility: searchQuery != undefined? "visible": "hidden"}}>
                <p className="title">Songs</p>
                <hr style={{marginTop: "5px"}}></hr>
                <div className="songsList" style={{display: "grid", gridTemplateRows: "auto auto auto", gridAutoFlow: "column"}}>
                    {songs.slice(0,6).map((song, index) => (
                        <div key={index} onClick={() => navigate(location, {state: { play: [song], index: 0 }})}>
                            <Track index={index + 1} track={song} user={user}/>
                        </div>
                    ))}
                </div>
                <p className="title">Albums</p>
                <img id="spanalbums" className="titlebtn spanbtn" src={ArrowImg} onClick={(e) => span(e,".albumsList")}></img>
                <hr style={{marginTop: "5px"}}></hr>
                <div className="albumsList">
                    {albums.slice(0,10).map((album) => (
                        <Album album = {album} key={album.id} />
                    ))}
                </div>
                <p className="title">Artists</p>
                <img id="spanalbums" className="titlebtn spanbtn" src={ArrowImg} onClick={(e) => span(e,".artistsList")}></img>
                <hr style={{marginTop: "5px"}}></hr>
                <div className="artistsList">
                    {artists.slice(0,10).map((artist) => (
                        <Artist artist = {artist} key={artist.id} />
                    ))}
                </div>
                <p className="title">Lists</p>
                <img id="spanalbums" className="titlebtn spanbtn" src={ArrowImg} onClick={(e) => span(e,".albumsList.lists")}></img>
                <hr style={{marginTop: "5px"}}></hr>
                <div className="albumsList lists">
                    {lists.slice(0,10).map((list) => (
                        <List list = {list} key={list.id} />
                    ))}
                </div>
            </main>
        </div>
    )
}