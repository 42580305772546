import { get, ref } from 'firebase/database'
import React, { useEffect, useState } from 'react'
import { db } from '../firebase'
import Track from './track'
import { Loading } from './Loading'
import { useLocation, useNavigate } from 'react-router-dom'

export default function PopularSongs({ id, user }) {
    const [Data, setData] = useState([])
    const [loaded, load] = useState(false)

    const navigate = useNavigate()
    const location = useLocation()

    async function fetchData() {
        const list = []
        const artistData = await get(ref(db, "users/" + id))
        const albums = artistData.child("albums").val()

        for (const albumKey in albums) {
            if (albumKey !== "a") {
                const albumData = await get(ref(db, "albums/" + albumKey))
                const tracks = albumData.child("tracks").val()

                for (const trackKey in tracks) {
                    if (trackKey !== "a") {
                        const snap = await get(ref(db, "tracks/" + trackKey))
                        let data = snap.val()
                        if (data.source == ""){
                            continue
                        }
                        data["album"] = albumData.val()
                        data.album["id"] = albumData.key
                        data["artist"] = artistData.val()
                        data.artist["id"] = artistData.key
                        data["id"] = trackKey
                        data["list"] = {
                            name: artistData.child("username").val(),
                            type: "users",
                            id: id
                        }
                        list.push(data)
                    }
                }
            }
        }
        setData(list)
        load(true)
    }

    useEffect(() => {
        if (id) {
            fetchData()
        }
    }, [id])

    if (!loaded) {
        return (<Loading />)
    }

    return (
        <div>
            {Data.sort(function(a,b) {return b.plays - a.plays}).slice(0,5).map((track, index) => (
                <div key={track.id} onClick={() => navigate(location, {state: { play: Data.sort(function(a,b) {return b.plays - a.plays}), index: index }})}>
                    <Track index={index + 1} track={track} user={user}/>
                </div>
            ))}
        </div>
    )
}
