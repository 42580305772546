import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Loading } from './Loading';
import { get, ref } from 'firebase/database';
import { db } from '../firebase';

export default function PlayTrack() {
    const {id} = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [songs, setSongs] = useState([]);
    const [albums, setAlbums] = useState([]);
    const [artists, setArtists] = useState([]);

    async function fetchData() {
        const tracks = await get(ref(db,"tracks"))
        const tracksKeys = []
        const albums = await get(ref(db,"albums"))
        const albumsKeys = []
        const users = await get(ref(db,"users"))
        const usersKeys = []
        users.forEach((user) => {
            if (user.key != "a"){
            const snap = user.val()
            snap["id"] = user.key
            snap.tracks = Object.keys(snap.albums).length > 1? Object.values(Object.keys(snap.albums).filter((i) => i != "a").map((albumId) => {
                const tracklist = Object.keys(albums.child(albumId).child("tracks").val()).map((trackId) => {return tracks.child(trackId).child("name").val()})
                return albumId
            })): null
            snap.albums = Object.keys(snap.albums).length > 1? Object.values(Object.keys(snap.albums).map((albumId) => {return albums.child(albumId).child("title").val()})): null
            usersKeys.push(snap)
            }
        })
        albums.forEach((album) => {
            if (album.key != "a"){
            const snap = album.val()
            snap["id"] = album.key
            snap["plays"] = 0
            album.child("tracks").forEach((track) => {snap.plays += tracks.child(track.key).child("plays").val()})  
            snap.tracks = Object.values(Object.keys(snap.tracks).map((trackId) => {return tracks.child(trackId).child("name").val()}))
            snap.tags = Object.keys(snap.tags)
            snap["artist"] =  usersKeys.find(function (value) {
                let found = false;
                users.child(value.id).child("albums").forEach((key) => {if (key.key == snap.id){found = true};})
                return found;
            })
            albumsKeys.push(snap)
            }
        })
        tracks.forEach((track) => {
            if (track.key != "a"){
            const snap = track.val()
            snap["id"] = track.key
            snap["album"] =  albumsKeys.find(function (value) {
                let found = false;
                albums.child(value.id).child("tracks").forEach((key) => {if (key.key == track.key){found = true};})
                return found;
            })
            snap["artist"] =  usersKeys.find(function (value) {
                let found = false;
                users.child(value.id).child("albums").forEach((key) => {if (key.key == snap.album.id){found = true};})
                return found;
            })
            snap["list"] = {
                name: snap.artist.username,
                type: "users",
                id: snap.artist.id
            }
            tracksKeys.push(snap)
            }
        })
        setSongs(tracksKeys);
        setAlbums(albumsKeys);
        setArtists(usersKeys);
    }

    useEffect(() => {
        fetchData();
    },[])
    useEffect(() => {
        fetchData();
        if (songs.length > 0) {
            const song = songs.find(a => a.id == id);
            console.log(song)
            navigate("/home", {state: { play: [song], index: 0 }});
        }
    },[songs])
  return (
    <main>
        <Loading></Loading>
    </main>
  )
}
