import React, { useEffect, useState } from 'react'
import { Loading } from './Loading'
import { numberWithCommas, toMinutesAndSeconds } from '../module'
import { Howl } from 'howler'
import { Link, useNavigate } from 'react-router-dom'
import MoreImg from "../Images/More.png"
import AddImg from "../Images/Add.png"
import RemveImg from "../Images/Remove.png"
import ShareImg from "../Images/Share.png"
import AlbumCoverImg from "../Images/AlbumCover.jpg"
import { useGlobalAudioPlayer } from 'react-use-audio-player'
import { get, ref, remove, set, update } from 'firebase/database'
import { db } from '../firebase'
import Share from './Share'

export default function Track ( {index, track, user} ) {

  const [length, setLength] = useState(0);
  const [menu, setMenu] = useState(false);
  const [lists, setLists] = useState([]);
  const [add, setAdd] = useState(true);
  const [sharing, setSharing] = useState(false);

  const navigate = useNavigate();

  async function showLists(e,adding) {
    e.stopPropagation();
    setAdd(adding);
    const listKeys = Object.keys((await get(ref(db, "users/" + user.uid + "/lists"))).val()).filter(a => a != "a")
    const data = []
    const tracks = await get(ref(db,"tracks"))
    const tracksKeys = []
    const albums = await get(ref(db,"albums"))
    const albumsKeys = []
    const users = await get(ref(db,"users"))
    const usersKeys = []
    users.forEach((u) => {
        if (u.key != "a"){
        const snap = u.val()
        snap["id"] = u.key
        snap.tracks = Object.keys(snap.albums).length > 1? Object.values(Object.keys(snap.albums).filter((i) => i != "a").map((albumId) => {
            const tracklist = Object.keys(albums.child(albumId).child("tracks").val()).map((trackId) => {return tracks.child(trackId).child("name").val()})
            return albumId
        })): null
        snap.plays = 0
        snap.albums = Object.keys(snap.albums).length > 1? Object.values(Object.keys(snap.albums).map((albumId) => {return albums.child(albumId).child("title").val()})): null
        usersKeys.push(snap)
        }
    })
    albums.forEach((album) => {
        if (album.key != "a"){
        const snap = album.val()
        snap["id"] = album.key
        snap["plays"] = 0
        album.child("tracks").forEach((track) => {snap.plays += tracks.child(track.key).child("plays").val()})
        snap.tracks = Object.values(Object.keys(snap.tracks).map((trackId) => {return tracks.child(trackId).child("name").val()}))
        snap.tags = Object.keys(snap.tags)
        snap["artist"] =  usersKeys.find(function (value) {
            let found = false;
            users.child(value.id).child("albums").forEach((key) => {if (key.key == snap.id){found = true};})
            return found;
        })
        usersKeys.find(a => a.id == snap.artist.id).plays += snap.plays
        albumsKeys.push(snap)
        }
    })
    tracks.forEach((track) => {
        if (track.key != "a"){
        const snap = track.val()
        snap["id"] = track.key
        snap["album"] =  albumsKeys.find(function (value) {
            let found = false;
            albums.child(value.id).child("tracks").forEach((key) => {if (key.key == track.key){found = true};})
            return found;
        })
        snap["artist"] =  usersKeys.find(function (value) {
            let found = false;
            users.child(value.id).child("albums").forEach((key) => {if (key.key == snap.album.id){found = true};})
            return found;
        })
        snap["list"] = {
            name: snap.artist.username,
            type: "users",
            id: snap.artist.id
        }
        tracksKeys.push(snap)
        }
    })
    for (let i in listKeys) {
      const snap = (await get(ref(db, "lists/" + listKeys[i]))).val()
      snap.id = listKeys[i]
      snap.cover_url = (Object.keys(snap.tracks).filter(a => a != "a").length == 0? AlbumCoverImg:
      tracksKeys.find(a => a.id == Object.keys(snap.tracks).filter(a => a != "a")[Math.floor(Math.random()*(Object.keys(snap.tracks).filter(a => a != "a").length-1))] )?.album.cover_url || AlbumCoverImg)
      data.push(snap)
    }
    setLists(data); 
  }

  useEffect(() => {
    if (track) {
      const sound = new Howl({
        src: [track.source],
        format:"mpeg",
        onload: () => {
          setLength(sound.duration());
          sound.unload()
        }
      })
    }
  }, []);

  return (
    <div className={"track " + track.id}>
      <Share opened={sharing} open={setSharing} type={"song"} id={track.id}></Share>
      {!menu?
        <div className="container">
            <p id="tracknumber">{index + "."}</p>
            <img id="trackcover" src={track.album.cover_url} alt=""></img>
            <div style={{marginLeft: "10px", width: "30%"}}>
              <p className='trackdisplayname' style={{margin: "0px", color: "white", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>{track.name}</p>
              <div onClick={(e) => {e.stopPropagation()}} style={{display: 'flex', gap: "5px"}}>
                <Link onMouseEnter={(e) => e.target.style.textDecoration = "underline"} onMouseLeave={(e) => e.target.style.textDecoration = "none"} style={{flexGrow: "0", flexShrink: "1", flexBasis: "fit-content", margin: "0px", padding: "0px", color: "var(--third-color)", textDecoration: "none", pointerEvents: "auto", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}} to={"/artists/" + track.artist.id} className={"link"}>{track.artist.username}</Link>
                <p style={{margin: "0px", padding: "0px", color: "var(--third-color)", whiteSpace: "nowrap"}}>·</p>
                <Link onMouseEnter={(e) => e.target.style.textDecoration = "underline"} onMouseLeave={(e) => e.target.style.textDecoration = "none"} style={{flexGrow: "0", flexShrink: "1", flexBasis: "fit-content", margin: "0px", padding: "0px", color: "var(--third-color)", textDecoration: "none", pointerEvents: "auto", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}} to={"/albums/" + track.album.id} className={"link"}>{track.album.title}</Link>
              </div>
            </div>
            <p style={{position: 'absolute', width: "100%", textAlign: "center", fontSize: "15px", color: "var(--third-color)"}}>{numberWithCommas(track.plays)}</p>
            <p style={{position: "absolute", right: "40px", fontSize: "15px", color: "var(--third-color)"}}>{toMinutesAndSeconds(length)}</p>
        </div>:
        <div className="container">
          <p id="tracknumber">{index + "."}</p>
          <img id="trackcover" src={track.album.cover_url} alt=""></img>
          <div style={{display: "flex", justifyContent: "space-evenly", width: "80%"}}>
            <div style={{display: "flex", alignItems: "center", gap: "10px"}} onClick={(e) => {showLists(e,true)}}>
              <img src={AddImg} style={{width: "30px", height: "30px"}}></img>
              <p style={{color: "var(--third-color)"}}>Add to a list</p>
            </div>
            <div style={{display: "flex", alignItems: "center", gap: "10px"}} onClick={(e) => {showLists(e,false)}}>
              <img src={RemveImg} style={{width: "30px", height: "30px"}}></img>
              <p style={{color: "var(--third-color)"}}>Remove from a list</p>
            </div>
            <div style={{display: "flex", alignItems: "center", gap: "10px"}} onClick={(e) => {e.stopPropagation();setSharing(true)}}>
              <img src={ShareImg} style={{width: "30px", height: "30px"}}></img>
              <p style={{color: "var(--third-color)"}}>Share</p>
            </div>
          </div>   
        </div>}
        <img style={{position: "absolute", right: "10px", top: "0px", height: "100%", width: "20px", objectFit: "contain"}} src={MoreImg} onClick={(e) => {
          e.stopPropagation();
          setMenu(!menu);
        }}></img>

        {lists.length > 0? 
          <div onClick={(e) => {
            e.stopPropagation();
            setLists([]);
          }} style={{position: "fixed", zIndex: "5", top: "0", left: "0", width: "100%", height: "100%", display: "flex", justifyContent: "end", alignItems: "end"}}>
            <div style={{width: "350px", height: "100%", display: "flex", flexDirection: "column", backgroundColor: "var(--second-color)", borderRadius: "20px"}}>
              <p style={{position: "absolute", right: "320px", top: "10px", margin: "0", fontSize: "20px", color: "var(--third-color)"}}>x</p>
              <p style={{color: "white", fontSize: "30px", marginTop: "100px", marginLeft: "15px", marginBottom: "10px"}}>Lists</p>
              <hr style={{width: "100%", marginTop: "0"}}></hr>
              {lists.map(list => (
                <div className={"track"} key={list.id} onClick={async (e) => {
                  e.stopPropagation();
                  if(add) {
                    await update(ref(db,"lists/" + list.id + "/tracks"),{
                      [track.id]: true
                    });
                    setLists([]);
                  } else {
                    await remove(ref(db,"lists/" + list.id + "/tracks/" + track.id));
                    setLists([]);
                  }
                }}>
                    <div className="container">
                        <img id="trackcover" src={list.cover_url} alt="" style={{marginLeft: "5px"}}></img>
                        <div style={{marginLeft: "10px", width: "30%"}}>
                          <p className='trackdisplayname' style={{margin: "0px", color: "white", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>{list.title}</p>
                        </div>
                    </div>
                </div>
              ))}
            </div>
          </div>:
          <div></div>
        }
    </div>
  )
}
