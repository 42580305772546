import React, { useEffect, useState } from 'react';
import { ref, getDatabase, get, onValue, remove } from 'firebase/database';
import { useList, useListKeys, useObject } from 'react-firebase-hooks/database';
import { auth, db } from '../firebase';
import { Navigate, useNavigate } from 'react-router-dom';

import EditImg from "../Images/Edit.png"

export default function TagsList({id}) {
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      onValue(ref(db, "albums/" + id + "/tags"), async (tags) => {
        const dataList = [];
        tags.forEach(tag => {
            dataList.push(tag);
        });
        setData(dataList);
      })
    };

    fetchData();
  }, []);

  return (
    <div>
        {data.filter(item => item.key !== "a").map(snapshot => (
            <p key={snapshot.key} style={{color: "var(--third-color)", display: "inline-block", margin: "10px"}}
            onMouseEnter={(e) => {
                e.target.textContent += " x"
                e.target.style.color = "#ababab"
            }}
            onMouseLeave={(e) => {
                e.target.textContent = "#" + snapshot.key
                e.target.style.color = "var(--third-color)"
            }}
            onClick={() => {
                remove(ref(db,"albums/" + id + "/tags/" + snapshot.key))
            }}>
                {"#" + snapshot.key}
            </p>
        ))}
    </div>
  );
};