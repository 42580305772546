import React, { createElement, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { Loading } from './Loading';
import { get, onValue, ref, remove, set, update } from 'firebase/database';
import { db } from '../firebase';
import NotFound from './404';
import { setBackColor, toMinutesAndSeconds, toTime } from '../module';
import { Howl } from 'howler';
import Track from './track';
import TagsList from './tagsList';
import EditImg from "../Images/Edit.png"
import AlbumCoverImg from "../Images/AlbumCover.jpg"
import Check from "../Images/Check.png"
import Add from "../Images/Add.png"
import ShareImg from "../Images/Share.png"
import addNotification from 'react-push-notification';
import Share from './Share';

export default function EditList ({user}) {
    const {id} = useParams();
    const [data, setData] = useState();
    const [isauthor, setIsAuthor] = useState(false);
    const [editTitle, setEditTitle] = useState("");
    const navigate = useNavigate();
    const location = useLocation();
    const [edit,setEdit] = useState(false);
    const [sharing, setSharing] = useState(false);
    const [coverIndex, setCoverIndex] = useState(0);

    async function fetchData() {
        const list = await get(ref(db,"lists/" + id))
        if (list.exists()) {
            setBackColor();

            const tracks = await get(ref(db,"tracks"))
            const tracksKeys = []
            const albums = await get(ref(db,"albums"))
            const albumsKeys = []
            const users = await get(ref(db,"users"))
            const usersKeys = []

            users.forEach((u) => {
                if (u.key != "a"){
                const snap = u.val()
                snap["id"] = u.key
                snap.tracks = Object.keys(snap.albums).length > 1? Object.values(Object.keys(snap.albums).filter((i) => i != "a").map((albumId) => {
                    const tracklist = Object.keys(albums.child(albumId).child("tracks").val()).map((trackId) => {return tracks.child(trackId).child("name").val()})
                    return albumId
                })): null
                snap.plays = 0
                snap.albums = Object.keys(snap.albums).length > 1? Object.values(Object.keys(snap.albums).map((albumId) => {return albums.child(albumId).child("title").val()})): null
                usersKeys.push(snap)
                }
            })
            albums.forEach((album) => {
                if (album.key != "a"){
                const snap = album.val()
                snap["id"] = album.key
                snap["plays"] = 0
                album.child("tracks").forEach((track) => {snap.plays += tracks.child(track.key).child("plays").val()})
                snap.tracks = Object.values(Object.keys(snap.tracks).map((trackId) => {return tracks.child(trackId).child("name").val()}))
                snap.tags = Object.keys(snap.tags)
                snap["artist"] =  usersKeys.find(function (value) {
                    let found = false;
                    users.child(value.id).child("albums").forEach((key) => {if (key.key == snap.id){found = true};})
                    return found;
                })
                usersKeys.find(a => a.id == snap.artist.id).plays += snap.plays
                albumsKeys.push(snap)
                }
            })

            const listData = [];
            listData.title = (await get(ref(db,"lists/" + id + "/title"))).val()

            tracks.forEach((track) => {
                if (track.key != "a"){
                const snap = track.val()
                snap["id"] = track.key
                snap["album"] =  albumsKeys.find(function (value) {
                    let found = false;
                    albums.child(value.id).child("tracks").forEach((key) => {if (key.key == track.key){found = true};})
                    return found;
                })
                snap["artist"] =  usersKeys.find(function (value) {
                    let found = false;
                    users.child(value.id).child("albums").forEach((key) => {if (key.key == snap.album.id){found = true};})
                    return found;
                })
                snap["list"] = {
                    name: listData.title,
                    type: "lists",
                    id: id
                }
                tracksKeys.push(snap)
                }
            })

            const listTracks = Object.keys((await get(ref(db, "lists/" + id + "/tracks"))).val()).filter(a => a != "a")
        
            setEditTitle(listData.title)
            listData.author = usersKeys.find(a => {
                return a.lists[id] != null
            })
            setIsAuthor(listData.author.id == user.uid)

            listData.tracks = [];
            for (let i in listTracks) {
                listData.tracks.push(tracksKeys.find(a => a.id == listTracks[i]));
            }
            listData.tracks.reverse();

            listData["duration"] = 0
            function loadSong(index) {
                const song = new Howl({
                    src: [listData.tracks[index].source],
                    format: "mpeg",
                    html5: true,
                    onload: () => {
                        listData["duration"] += song.duration();
                        if (listData.tracks[index + 1]) {loadSong(index + 1)}
                        else {setData(listData); navigate(location, { state: { loaded: true } })}
                    }
                })
            }
            if (listData.tracks.length > 0) {
               loadSong(0) 
            }else{
                setData(listData);
                navigate(location, { state: { loaded: true } })
            } 
        }
        else {
            return (<NotFound />);
        }
    }

    useEffect(() => {
        if (user) {
            fetchData();
            onValue(ref(db,"lists/" + id + "/tracks"),() => {fetchData()});
        }
    },[user, edit])

    useEffect(() => {
        if (data) {
            setCoverIndex(Math.floor(Math.random()*data.tracks.length));
            setBackColor(data.tracks.length > 0? data.tracks[coverIndex].album.cover_url:AlbumCoverImg)
        }
    },[data])

    if (!data || !user) {
        return (<main><Loading /></main>)
    }

  return (
    <main className="play-album">
        <Share opened={sharing} open={setSharing} type={"album"} id={id}></Share>
        <div id="albumTitle">
            <img src={data.tracks.length > 0? data.tracks[coverIndex].album.cover_url:AlbumCoverImg}></img>
            <div>
                {!edit? <div style={{margin: "0", display: "inline"}}><p className='title' style={{display: "inline-block"}}>{data.title}</p>
                <img id="editbutton" src={EditImg} style={{width: "40px", height: "40px", display: isauthor? "inline-block": "none", marginInline: "15px", marginBlock: "0px", translate: "0 5px"}} onClick={() => {setEdit(true)}} alt=""></img></div>:
                <div style={{margin: "0", display: "inline"}}><input type='text' style={{translate: "0 -10px", fontSize: "20px", display: "inline-block", marginTop: "80px"}} onChange={(e) => {setEditTitle(e.target.value)}} value={editTitle}></input>
                <img id="editbutton" src={Check} style={{width: "45px", height: "45px", display: "inline-block", marginInline: "5px", marginBlock: "0px", translate: "0 6px"}} onClick={() => {
                    update(ref(db,"lists/" + id),{
                        title: editTitle
                    })
                    setEdit(false)
                }} alt=""></img></div>}
                <Link onMouseEnter={(e) => e.target.style.textDecoration = "underline"} onMouseLeave={(e) => e.target.style.textDecoration = "none"} style={{display: "block", margin: "0px", padding: "0px", color: "var(--third-color)", textDecoration: "none", pointerEvents: "auto", fontSize: "20px"}} to={"/artists/" + data.author.id} className={"link"}>{data.author.username}</Link>
                <p style={{display: "inline", margin: "0px", color: "var(--third-color)", fontSize: "20px"}}>{data.date ? " · " + (new Date(data.date.replace(/-/g, '\/'))).toLocaleDateString("en-GB", {day: "numeric", month: "long", year: "numeric"}): ""}</p>
            </div>
        </div>
        <p style={{position: "absolute", right: "20px", top: "115px", color: "var(--third-color)"}}>{data.tracks.length + " songs · " + toTime(data.duration)}</p>
        <hr className="hr2"/>
        <div style={{marginLeft: "15px", display: "flex", alignItems: "center", gap: "10px"}} onClick={(e) => {setSharing(true)}}>
              <img src={ShareImg} style={{width: "30px", height: "30px"}}></img>
              <p style={{color: "var(--third-color)"}}>Share</p>
        </div>
        <div>
            <div style={{display: isauthor? "block": "none", margin: "10px"}} className={"track"} onClick={() => {
                navigate("/explore")
            }}>
                <div className="container">
                    <img id="trackcover" src={Add} alt="" style={{marginLeft: "5px"}}></img>
                    <div style={{marginLeft: "10px", width: "30%"}}>
                      <p className='trackdisplayname' style={{margin: "0px", color: "white", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>Add songs</p>
                    </div>
                </div>
            </div>
            {data.tracks.map((track, index) => (
                <div key={track.id} onClick={() => navigate(location, { state: { play: data.tracks, index: index } })}>
                    <Track index={index + 1} track={track} user={user}></Track>
                </div>
            ))}
        </div>
    </main>
  )
}
