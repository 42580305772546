import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { GoogleAuthProvider, linkWithPopup, signInWithEmailAndPassword, signInWithPopup, sendPasswordResetEmail } from "firebase/auth";
import { auth, db } from "../firebase.js";
import { setBackByColor, Toast } from "../module.js";

import GoogleImg from "../Images/Google.png";
import { get, ref, set } from "firebase/database";

export default function Login() {
    
    const navigate = useNavigate();
    const provider = new GoogleAuthProvider();

    var errors = {
        "auth/invalid-credential": "Password or email address are not correct",
        "auth/invalid-email": "Invalid email address",
        "auth/user-disabled": "The user corresponding to the given email has been disabled",
        "auth/user-not-found": "User not found with the given email",
        "auth/wrong-password": "Password or email address are not correct",
        "auth/too-many-requests": "Too many requests sent at the same time, please try again later",
        "auth/user-token-expired": "User token expired",
        "auth/network-request-failed": "Internet connection error"
    }

    async function login(e) {
        e.preventDefault()
    
        const email = document.querySelector("#inputemail").value
        const password = document.querySelector("#inputpassword").value
    
        try {
            const userCredentials = await signInWithEmailAndPassword(auth, email, password)
            console.log(userCredentials)
            navigate("/home")
        } catch (error) {
            console.log(error.code)
            Toast(errors[error.code],3000)
        }
    }

    useEffect(() => {
        setBackByColor("var(--main-color)");
    },[])

    return (
        <div className="body">
        <div className="wrapper">
            <p className="title">Log in</p>
            <div>
                <form className="userdata" onSubmit={login}>
                    <div className="form">
                        <input id="inputemail" type="email" name="text" autoComplete="off" required></input>
                        <label htmlFor="text" className="label-name">
                        <span className="content-name">Email address</span>
                        </label>
                    </div>
                    <div className="form">
                        <input id="inputpassword" type="password" name="text" autoComplete="off" required></input>
                        <label htmlFor="text" className="label-name">
                            <span className="content-name">Password</span>
                        </label>
                    </div>
                    <button type="submit">Log in</button>
                </form>
                <div className="google">
                    <p>or</p>
                    <button
                    onClick={() => {
                        signInWithPopup(auth, provider)
                            .then( async (result) => {
                              // This gives you a Google Access Token. You can use it to access the Google API.
                              const credential = GoogleAuthProvider.credentialFromResult(result);
                              const token = credential.accessToken;
                              // The signed-in user info.
                              const user = result.user;
                              // IdP data available using getAdditionalUserInfo(result)

                              const data = Object.keys((await get(ref(db,"users"))).val()).find(u => u == user.uid)

                              if (data == undefined) {
                                await set(ref(db, "users/" + user.uid), {
                                    username: "User",
                                    email_address: user.email,
                                    image_url: "https://firebasestorage.googleapis.com/v0/b/tdrfirebase-cef0a.appspot.com/o/src%2FUser.jpg?alt=media&token=d80d0882-d5d7-410f-b173-d1af15927df3",
                                    public: false,
                                    description: "",
                                    followers: {
                                        a: false
                                    },
                                    following: {
                                        a: false
                                    },
                                    links: {
                                        a: false
                                    },
                                    record: {
                                        a: false
                                    },
                                    albums: {
                                        a: false
                                    },
                                    lists: {
                                        a: false
                                    },
                                    favourite: {
                                        a: false
                                    },
                                    notifications: {
                                        a: false
                                    }
                                })
                              }

                              navigate("/home")
                            }).catch((error) => {
                              // Handle Errors here.
                              const errorCode = error.code;
                              const errorMessage = error.message;
                              // The email of the user's account used.
                              const email = error.customData.email;
                              // The AuthCredential type that was used.
                              const credential = GoogleAuthProvider.credentialFromError(error);
                              console.log(errorCode + ": " + errorMessage)
                            });
                    }}>
                        <img src={GoogleImg} alt=""></img>
                        Continue with Google
                    </button>
                </div>
            </div>
            <hr className="hr2"/>
            <p>Don't have an account? </p>{" "}
            <NavLink to={"/register"}>Sign up</NavLink>
        </div>
        </div>
    )
}