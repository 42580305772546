import React, { useEffect } from 'react'
import { setBackByColor } from '../module';
import { useNavigate } from 'react-router-dom';

export default function NotFound() {

  const navigate = useNavigate();

  useEffect(() => {
    setBackByColor("rgba(255,0,0,.4)");
  },[])

  return (
    <main>
      <div style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", width: "100%", height: "80%"}}>
        <p style={{color: "white", fontSize: "200px", margin: "0", padding: "0"}}>404</p>
        <p style={{color: "white", fontSize: "35px", margin: "0", padding: "0"}}>PAGE NOT FOUND!</p>
        <button style={{color: "red", fontSize: "20px", backgroundColor: "transparent", margin: "20px", border: "solid red", borderRadius: "10px"}} onClick={() => {
          navigate("/home");
        }}>GO HOME</button>
      </div>
    </main>
  )
}
