import React, { useEffect, useState } from 'react';
import { ref, getDatabase, get, onValue, remove, orderByValue, query } from 'firebase/database';
import { useList, useListKeys, useObject } from 'react-firebase-hooks/database';
import { auth, db } from '../firebase';
import { Navigate, useNavigate } from 'react-router-dom';

import UploadImg from "../Images/Upload.png"
import CoverImg from "../Images/AlbumCover.jpg"
import PlayImg from "../Images/Play.png"
import TrackEdit from './trackEdit';

export default function TracksEditList({id}) {
  const [data, setData] = useState([]);
  const [albumCover, setCover] = useState(CoverImg);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {

        onValue(ref(db, "albums/" + id + "/tracks"), async (trackkeys) => {
          const snapshot = await get(query(ref(db, "tracks"), orderByValue()));
  
          if (snapshot.exists()) {
            const dataList = [];
            trackkeys.forEach(track => {
              dataList.push(snapshot.child(track.key));
            });
            console.log(dataList)
            setData(dataList);
          } else {
            console.log("No data available");
          }
        })
      };

    fetchData();
  }, []);

  return (
    <div>
        {data.filter(item => item.key !== "a").map((snapshot) => (
            <TrackEdit id={snapshot.key} album={id} key={snapshot.key}/>
        ))}
    </div>
  );
};