import React, { useEffect, useState } from "react";
import { db } from "../firebase";
import { setBackColor, span } from "../module";
import ArrowImg from "../Images/Arrow.png";
import { get, ref } from "firebase/database";
import Album from "./album";
import Artist from "./artist";
import Fuse from "fuse.js";
import { Link } from "react-router-dom";
import List from "./list";

export default function Home({user}) {

    const [last, setLast] = useState([]);
    const [recomended, setRecomended] = useState([]);
    const [following, setFollowing] = useState([]);
    const [trArtists, setTrArtists] = useState([]);
    const [trAlbums, setTrAlbums] = useState([]);

    async function fetchData() {

        const tracks = await get(ref(db,"tracks"))
        const tracksKeys = []
        const albums = await get(ref(db,"albums"))
        const albumsKeys = []
        const users = await get(ref(db,"users"))
        const usersKeys = []
        const lists = await get(ref(db,"lists"))
        const listsKeys = []

        users.forEach((u) => {
            if (u.key != "a"){
            const snap = u.val()
            snap["id"] = u.key
            snap.tracks = Object.keys(snap.albums).length > 1? Object.values(Object.keys(snap.albums).filter((i) => i != "a").map((albumId) => {
                const tracklist = Object.keys(albums.child(albumId).child("tracks").val()).map((trackId) => {return tracks.child(trackId).child("name").val()})
                return albumId
            })): null
            snap.plays = 0
            snap.albums = Object.keys(snap.albums).length > 1? Object.values(Object.keys(snap.albums).map((albumId) => {return albums.child(albumId).child("title").val()})): null
            usersKeys.push(snap)
            }
        })
        albums.forEach((album) => {
            if (album.key != "a"){
            const snap = album.val()
            snap["id"] = album.key
            snap["plays"] = 0
            album.child("tracks").forEach((track) => {snap.plays += tracks.child(track.key).child("plays").val()})
            snap.tracks = Object.values(Object.keys(snap.tracks).map((trackId) => {return tracks.child(trackId).child("name").val()}))
            snap.tags = Object.keys(snap.tags)
            snap["artist"] =  usersKeys.find(function (value) {
                let found = false;
                users.child(value.id).child("albums").forEach((key) => {if (key.key == snap.id){found = true};})
                return found;
            })
            usersKeys.find(a => a.id == snap.artist.id).plays += snap.plays
            albumsKeys.push(snap)
            }
        })
        tracks.forEach((track) => {
            if (track.key != "a"){
            const snap = track.val()
            snap["id"] = track.key
            snap["album"] =  albumsKeys.find(function (value) {
                let found = false;
                albums.child(value.id).child("tracks").forEach((key) => {if (key.key == track.key){found = true};})
                return found;
            })
            snap["artist"] =  usersKeys.find(function (value) {
                let found = false;
                users.child(value.id).child("albums").forEach((key) => {if (key.key == snap.album.id){found = true};})
                return found;
            })
            snap["list"] = {
                name: snap.artist.username,
                type: "users",
                id: snap.artist.id
            }
            tracksKeys.push(snap)
            }
        })
        lists.forEach((list) => {
            if (list.key != "a"){
            const snap = list.val()
            snap["id"] = list.key
            snap.tracks = Object.values(Object.keys(snap.tracks).map((trackId) => {return tracksKeys.find(a => a.id == trackId)}))
            snap["author"] =  usersKeys.find(function (value) {
                let found = false;
                users.child(value.id).child("lists").forEach((key) => {if (key.key == snap.id){found = true};})
                return found;
            })
            listsKeys.push(snap)
            }
        })

        if (user) {
            const record = Object.values((await get(ref(db, "users/" + user.uid + "/record"))).val())
                .filter((a) => a != false)
                .sort((a,b) => new Date(b.date) - new Date(a.date))
                .slice(0,100);
            
            const unique = [];
            const recQuery = [];

            for (let i in record) {
                record[i].list.obj = 
                    record[i].list.type == "albums"? albumsKeys.find((a) => a.id == record[i].list.id):
                    record[i].list.type == "users"? usersKeys.find((a) => a.id == record[i].list.id):
                    record[i].list.type == "lists"? listsKeys.find((a) => a.id == record[i].list.id): null;
                
                if (unique.find(a => a.list.id == record[i].list.id) == undefined) {
                    unique.push(record[i])
                }

                if (record[i].list.type == "albums") {
                    recQuery.push(record[i].list.obj.genre);
                    record[i].list.obj.tags.filter(a => a != "a").forEach((tag) => {
                        recQuery.push(tag);
                    })
                }
                else if (record[i].list.type == "users") {
                    recQuery.push(tracksKeys.find(t => t.id == record[i].id).album.genre);
                    tracksKeys.find(t => t.id == record[i].id).album.tags.filter(a => a != "a").forEach((tag) => {
                        recQuery.push(tag);
                    })
                }
            }
            
            setLast(unique.slice(0,10));
            const albumsData = [];

            const albumsfuse = new Fuse(albumsKeys,{
                includeScore: true,
                shouldSort: true,
                findAllMatches: true,
                ignoreLocation: true,
                ignoreFieldNorm: true,
                threshold: 0.75,
                keys: ["title", "extra", "tags.", "genre"]
            })
            albumsfuse.search(recQuery.join(" ")).sort((a, b) => {
                if (a.score === b.score) {
                    return b.item.plays - a.item.plays;
                }
                return a.score - b.score;
            }).forEach((item) => {
                if (item.item.artist.public == true) {
                    albumsData.push(item.item);
                }
            })

            setRecomended(albumsData.slice(0,10));

            const followed = [];
            const followData = (await get(ref(db,"users/" + user.uid + "/following"))).val();
            Object.keys(followData).filter(a => a != "a").forEach(artist => {
                albumsKeys.filter(a => a.artist.id == artist).forEach(album => {
                    followed.push(album)
                })
            })
            followed.sort((a,b) => new Date(b.date) - new Date(a.date))

            setFollowing(followed.slice(0,10))
        }
        else {
            setTrArtists(usersKeys.filter(a => a.public == true).sort((a,b) => {return b.plays - a.plays}).slice(0,10))
            setTrAlbums(albumsKeys.filter(a => a.artist.public == true).sort((a,b) => {return b.plays - a.plays}).slice(0,10))
        }
    }

    useEffect(() => {
        setBackColor();
        fetchData();
    },[user])

    if (!user) {
        return (
            <main className="Home">
                <div style={{display: "flex", gap: "20px", backgroundColor: "var(--second-color)", margin: "20px", borderRadius: "20px", flexDirection: "column", justifyContent: "center", alignItems: "center", padding: "10px"}}>
                    <p style={{margin: "0", color: "white", fontSize: "25px"}}>Welcome to Loudify!</p>
                    <div style={{display: "flex", gap: "10px", alignItems: "center"}}>
                        <Link to={"/register"} style={{fontSize: "20px", color: "var(--second-color)", backgroundColor: "white", border: "none", borderRadius: "10px", textDecoration: "none", paddingInline: "10px", paddingBottom: "5px"}}>Create an account</Link>
                        <p style={{margin: "0", color: "white"}}>or</p>
                        <Link to={"/login"} style={{fontSize: "18px", color: "white"}}>Log in</Link>
                    </div>
                </div>
                <p className="title">Trending Artists</p>
                <img id="spanalbums" className="titlebtn spanbtn" src={ArrowImg} onClick={(e) => span(e,".artistsList.artists")}></img>
                <hr style={{marginTop: "5px"}}></hr>
                <div className="artistsList artists">
                    {trArtists.map((artist) => (
                        <Artist key={artist.id} artist={artist} />
                    ))}
                </div>
                <p className="title">Trending Albums</p>
                <img id="spanalbums" className="titlebtn spanbtn" src={ArrowImg} onClick={(e) => span(e,".artistsList.albums")}></img>
                <hr style={{marginTop: "5px"}}></hr>
                <div className="artistsList albums">
                    {trAlbums.map((album) => (
                        <Album key={album.id} album={album} />
                    ))}
                </div>
            </main>
        )
    }

    return (
        <main className="Home">
            <p className="title">Listen again</p>
            <img id="spanalbums" className="titlebtn spanbtn" src={ArrowImg} onClick={(e) => span(e,".artistsList.last")}></img>
            <hr style={{marginTop: "5px"}}></hr>
            <div className="artistsList last">
                {last.map((record) => (
                    record.list.type == "albums"? <Album key={record.list.id} album={record.list.obj} />:
                    record.list.type == "users"? <Artist key={record.list.id} artist={record.list.obj} />:
                    record.list.type == "lists"? <List key={record.list.id} list={record.list.obj} />:
                    <p key={record.list.id}/>
                ))}
            </div>
            <p className="title">Recomended</p>
            <img id="spanalbums" className="titlebtn spanbtn" src={ArrowImg} onClick={(e) => span(e,".artistsList.recomended")}></img>
            <hr style={{marginTop: "5px"}}></hr>
            <div className="artistsList recomended">
                {recomended.map((album) => (
                    <Album key={album.id} album={album} />
                ))}
            </div>
            <p className="title">Artists you follow</p>
            <img id="spanalbums" className="titlebtn spanbtn" src={ArrowImg} onClick={(e) => span(e,".artistsList.recomended")}></img>
            <hr style={{marginTop: "5px"}}></hr>
            <div className="artistsList recomended">
                {following.map((album) => (
                    <Album key={album.id} album={album} />
                ))}
            </div>
        </main>
    )
}