import React, { useEffect, useState } from 'react'
import { useList, useObject } from "react-firebase-hooks/database"
import { Navigate, useLocation, useParams } from 'react-router-dom'

import { onAuthStateChanged } from 'firebase/auth'
import { set, update, ref, onValue, push, remove, get, child } from 'firebase/database'
import { ref as storageRef, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage'
import { auth, db, storage } from '../firebase'
import { getAverageRGB, numberWithCommas, setBackColor, Toast, span } from '../module'
import { useAuth } from './AuthProvider'

import UserAlbumsList from './userAlbumsList'

import UserImg from "../Images/User.jpg"
import EditImg from "../Images/Edit.png"
import UploadImg from "../Images/Upload.png"
import ArrowImg from "../Images/Arrow.png"
import { useNavigate } from 'react-router-dom'
import LinksList from './linksList'
import { Loading } from './Loading'
import PopularSongs from './popularSongs'
import Album from './album'
import List from './list'

export default function About({user}) {

    const {id} = useParams();

    const navigate = useNavigate();
    const location = useLocation();

    const [descOpened, openDesc] = useState(false);
    const [following, follow] = useState(false);

    const [albums, setAlbums] = useState([]);
    const [lists, setLists] = useState([]);

    function openDescFrame() {
        openDesc(!descOpened);
    };

    async function handleFollow() {
        if (following) {
            await remove(ref(db, "users/" + id + "/followers/" + user.uid))
            await remove(ref(db, "users/" + user.uid + "/following/" + id))
        } else {
            await update(ref(db, "users/" + id + "/followers"),{
                [user.uid]: true
            })
            await update(ref(db, "users/" + user.uid + "/following"),{
                [id]: true
            })
        }
        fetchData()

    }

    const fetchData = async () => {
        const snapshot = await get(ref(db,"users/" + id))
        
        if (snapshot) {
            setBackColor(snapshot.child("image_url").val())
            if (snapshot.child("followers").child(user.uid).exists()) {
                follow(true)
            } else {
                follow(false)
            }

            document.querySelectorAll("#profileimg").forEach((item) => {item.style.backgroundImage = "url(" + snapshot.child("image_url").val() + ")"})
            document.querySelectorAll("#name").forEach((item) => {item.textContent = snapshot.child("username").val()})
            document.querySelectorAll("#description").forEach((item) => {item.textContent = snapshot.child("description").val()})
            document.querySelectorAll("#followers").forEach((item) => {item.textContent = numberWithCommas(Object.keys(snapshot.child("followers").val()).length - 1) + " follower/s"})
            
            const albumList = []
            const albumsSnap = await get(ref(db, "albums"))
            const users = await get(ref(db,"users"))
            let usersKeys = []

            users.forEach((user) => {
                if (user.key != "a"){
                const snap = user.val()
                snap["id"] = user.key
                usersKeys.push(snap)
                }
            })

            for (let albumKey in snapshot.child("albums").val()) {
                if (albumKey != "a") {
                    const snap = albumsSnap.child(albumKey).val()
                    snap.id = albumKey
                    snap["artist"] =  usersKeys.find(function (value) {
                        let found = false;
                        users.child(value.id).child("albums").forEach((key) => {if (key.key == snap.id){found = true};})
                        return found;
                    })
                    albumList.push(snap)
                }
            }

            snapshot.child("albums").forEach(((al) => {
                
            }))
            setAlbums(albumList)

            const tracks = await get(ref(db,"tracks"))
            const tracksKeys = []
            const albums = await get(ref(db,"albums"))
            const albumsKeys = []
            usersKeys = []
            const lists = await get(ref(db,"lists"))
            const listsKeys = []

            users.forEach((user) => {
                if (user.key != "a"){
                const snap = user.val()
                snap["id"] = user.key
                snap.tracks = Object.keys(snap.albums).length > 1? Object.values(Object.keys(snap.albums).filter((i) => i != "a").map((albumId) => {
                    const tracklist = Object.keys(albums.child(albumId).child("tracks").val()).map((trackId) => {return tracks.child(trackId).child("name").val()})
                    return albumId
                })): null
                snap.albums = Object.keys(snap.albums).length > 1? Object.values(Object.keys(snap.albums).map((albumId) => {return albums.child(albumId).child("title").val()})): null
                usersKeys.push(snap)
                }
            })
            albums.forEach((album) => {
                if (album.key != "a"){
                const snap = album.val()
                snap["id"] = album.key
                snap["plays"] = 0
                album.child("tracks").forEach((track) => {snap.plays += tracks.child(track.key).child("plays").val()})  
                snap.tracks = Object.values(Object.keys(snap.tracks).map((trackId) => {return tracks.child(trackId).child("name").val()}))
                snap.tags = Object.keys(snap.tags)
                snap["artist"] =  usersKeys.find(function (value) {
                    let found = false;
                    users.child(value.id).child("albums").forEach((key) => {if (key.key == snap.id){found = true};})
                    return found;
                })
                albumsKeys.push(snap)
                }
            })
            tracks.forEach((track) => {
                if (track.key != "a"){
                const snap = track.val()
                snap["id"] = track.key
                snap["album"] =  albumsKeys.find(function (value) {
                    let found = false;
                    albums.child(value.id).child("tracks").forEach((key) => {if (key.key == track.key){found = true};})
                    return found;
                })
                snap["artist"] =  usersKeys.find(function (value) {
                    let found = false;
                    users.child(value.id).child("albums").forEach((key) => {if (key.key == snap.album.id){found = true};})
                    return found;
                })
                snap["list"] = {
                    name: snap.artist.username,
                    type: "users",
                    id: snap.artist.id
                }
                tracksKeys.push(snap)
                }
            })

            lists.forEach((list) => {
                if (list.key != "a"){
                const snap = list.val()
                snap["id"] = list.key
                snap.tracks = Object.values(Object.keys(snap.tracks).map((trackId) => {return tracksKeys.find(a => a.id == trackId)}))
                snap["author"] =  usersKeys.find(function (value) {
                    let found = false;
                    users.child(value.id).child("lists").forEach((key) => {if (key.key == snap.id){found = true};})
                    return found;
                })
                listsKeys.push(snap)
                }
            })

            setLists(listsKeys.filter(a => a.author.id == id));
        }
    };

    const [links, setData] = useState([])
    useEffect(() => {
        onValue(ref(db, "users/" + id + "/links"), (snap) => {
            if (snap.exists()) {
                const dataList = []
                snap.forEach((child) => {
                    dataList.push(child)
                })
                setData(dataList)
            }
        })
    },[])

    useEffect( () => {
        if (id && user) {
            fetchData();
        }
    },[user]);

    if (!id || !user) {
        return (<main><Loading /></main>)
    };
  return (
    <div className="about">
        <div id="overlay" style={{visibility: descOpened ? "visible": "hidden", backgroundColor: "rgba(0,0,0,0.8)", zIndex: 5}} onClick={openDescFrame}></div>

        <div id="descFrame" style={{display: descOpened ? "flex": "none"}}>
            <div id="innerframe">
                <button id="close" onClick={openDescFrame}>x</button>
                <div>
                    <div className='info'>
                        <p id='name'>Artist</p>
                        <p id='followers'>100,000 followers</p>
                    </div>
                    <div style={{display: "block", textAlign: "left", margin: "20px"}}>
                        {links.filter(item => item.key !== "a").map(snapshot => (
                            <div style={{display: "block"}} key={snapshot.val()}>
                                <p id="linkname">{"· " + snapshot.key + ":"}</p>{" "}
                                <a href={snapshot.val()} id="linkhref">{snapshot.val()}</a>
                            </div>
                        ))}
                    </div>
                    <p id='description'>...</p>
                    <br></br>
                </div>
                
            </div>
        </div>

        <main >
            <div className='leftframe'>
                <div id='profileimg'><div onClick={openDescFrame}>
                    <p id='name'>Artist</p>
                    <p id='followers'>100,000 followers</p>
                    </div>
                <button id='follow' style={{display: user.uid == id ? "none": "unset"}} onClick={handleFollow}>{following ? "Unfollow": "Follow"}</button></div>
                <p className='title'  onClick={openDescFrame}>Description</p>
                <p id='description'  onClick={openDescFrame}>...</p>
            </div>
            <div className='rightframe'>
                <div>
                    <p className='title'>Popular</p>
                    <hr style={{marginTop: "5px"}}></hr>
                    <div>
                        <PopularSongs id={id} user={user} />
                    </div>
                    <p className='title'>Albums</p>
                    <img id="spanalbums" className="titlebtn spanbtn" src={ArrowImg} onClick={(e) => span(e,".albumsList")}></img>
                    <hr style={{marginTop: "5px"}}></hr>
                    <div className='albumsList'>
                        {albums.slice(0,10).map((album) => (
                            <Album album = {album} key={album.id} />
                        ))}
                    </div>
                    <p className='title'>Lists</p>
                    <img id="spanalbums" className="titlebtn spanbtn" src={ArrowImg} onClick={(e) => span(e,".albumsList.lists")}></img>
                    <hr style={{marginTop: "5px"}}></hr>
                    <div className='albumsList lists'>
                        {lists.map((list) => (
                            <List key={list.id} list={list}></List>
                        ))}
                    </div>
                </div>
            </div>
        </main>
    </div>
  )
}
