import React, { useEffect, useState } from 'react'
import { setBackColor, span } from "../module";
import ArrowImg from "../Images/Arrow.png";
import { get, push, ref, set, update } from 'firebase/database';
import { db } from '../firebase';
import Album from './album';
import Artist from './artist';
import { useNavigate } from 'react-router-dom';
import List from './list';

export default function Library({user}) {

  const [favourite, setFavourite] = useState([]);
  const [following, setFollowing] = useState([]);
  const [lists, setLists] = useState([]);

  const navigate = useNavigate();

  async function fetchData() {

    const tracks = await get(ref(db,"tracks"))
    const tracksKeys = []
    const albums = await get(ref(db,"albums"))
    const albumsKeys = []
    const users = await get(ref(db,"users"))
    const usersKeys = []

    users.forEach((u) => {
        if (u.key != "a"){
        const snap = u.val()
        snap["id"] = u.key
        snap.tracks = Object.keys(snap.albums).length > 1? Object.values(Object.keys(snap.albums).filter((i) => i != "a").map((albumId) => {
            const tracklist = Object.keys(albums.child(albumId).child("tracks").val()).map((trackId) => {return tracks.child(trackId).child("name").val()})
            return albumId
        })): null
        snap.plays = 0
        snap.albums = Object.keys(snap.albums).length > 1? Object.values(Object.keys(snap.albums).map((albumId) => {return albums.child(albumId).child("title").val()})): null
        usersKeys.push(snap)
        }
    })
    albums.forEach((album) => {
        if (album.key != "a"){
        const snap = album.val()
        snap["id"] = album.key
        snap["plays"] = 0
        album.child("tracks").forEach((track) => {snap.plays += tracks.child(track.key).child("plays").val()})
        snap.tracks = Object.values(Object.keys(snap.tracks).map((trackId) => {return tracks.child(trackId).child("name").val()}))
        snap.tags = Object.keys(snap.tags)
        snap["artist"] =  usersKeys.find(function (value) {
            let found = false;
            users.child(value.id).child("albums").forEach((key) => {if (key.key == snap.id){found = true};})
            return found;
        })
        usersKeys.find(a => a.id == snap.artist.id).plays += snap.plays
        albumsKeys.push(snap)
        }
    })
    tracks.forEach((track) => {
        if (track.key != "a"){
        const snap = track.val()
        snap["id"] = track.key
        snap["album"] =  albumsKeys.find(function (value) {
            let found = false;
            albums.child(value.id).child("tracks").forEach((key) => {if (key.key == track.key){found = true};})
            return found;
        })
        snap["artist"] =  usersKeys.find(function (value) {
            let found = false;
            users.child(value.id).child("albums").forEach((key) => {if (key.key == snap.album.id){found = true};})
            return found;
        })
        snap["list"] = {
            name: snap.artist.username,
            type: "users",
            id: snap.artist.id
        }
        tracksKeys.push(snap)
        }
    })

    const favSnap = Object.keys((await get(ref(db,"users/" + user.uid + "/favourite"))).val()).filter(a => a != "a")
    const favList = []

    for (let i in favSnap) {
      favList.push(albumsKeys.find(a => a.id == favSnap[i]))
    }
    setFavourite(favList);

    const folSnap = Object.keys((await get(ref(db,"users/" + user.uid + "/following"))).val()).filter(a => a != "a")
    const folList = []

    for (let i in folSnap) {
      folList.push(usersKeys.find(a => a.id == folSnap[i]))
    }
    setFollowing(folList);

    const listsData = Object.keys((await get(ref(db, "users/" + user.uid + "/lists"))).val()).filter(a => a != "a")
    const listsSnap = [];
    for (let i in listsData) {
      const listData = [];

      const listTracks = Object.keys((await get(ref(db, "lists/" + listsData[i] + "/tracks"))).val()).filter(a => a != "a")

      listData.title = (await get(ref(db,"lists/" + listsData[i] + "/title"))).val()
      listData.author = usersKeys.find(a => a.id == user.uid)
      listData.id = listsData[i]

      listData.tracks = [];
      for (let i in listTracks) {
        listData.tracks.push(tracksKeys.find(a => a.id == listTracks[i]));
      }
      listsSnap.push(listData);
    }
    setLists(listsSnap);
  }

  useEffect(() => {
    if (user) {
      fetchData();
    }
    setBackColor();
  },[user])

  return (
    <main className='Library'>
        <p className="title">Lists</p>
        <button className="titlebtn" id="addlist" onClick={async () => {
          const reference = await push(ref(db, "lists"))
          await set(reference, {
              title: "New list",
              tracks: {
                  a: false
              }
          })
          const listData = await get(reference)
          await update(ref(db, "users/" + user.uid + "/lists"), {
              [listData.key]: true
          })
          navigate("/lists/" + listData.key)
        }}>+ New</button>
        <img id="spanalbums" className="titlebtn spanbtn" src={ArrowImg} onClick={(e) => span(e,".artistsList.lists")}></img>
        <hr style={{marginTop: "5px"}}></hr>
        <div className="artistsList lists">
          {lists.map((list) => (
            <List key={list.id} list={list}></List>
          ))}
        </div>
        <p className="title">Favourite Albums</p>
        <img id="spanalbums" className="titlebtn spanbtn" src={ArrowImg} onClick={(e) => span(e,".artistsList.favAlbums")}></img>
        <hr style={{marginTop: "5px"}}></hr>
        <div className="artistsList favAlbums">
          {favourite.map((album) => (
            <Album key={album.id} album={album}></Album>
          ))}
        </div>
        <p className="title">Following</p>
        <img id="spanalbums" className="titlebtn spanbtn" src={ArrowImg} onClick={(e) => span(e,".artistsList.following")}></img>
        <hr style={{marginTop: "5px"}}></hr>
        <div className="artistsList following">
          {following.map((artist) => (
            <Artist key={artist.id} artist={artist}></Artist>
          ))}
        </div>
    </main>
  )
}
