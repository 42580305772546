import React from "react";
import { useLocation } from "react-router-dom";

import Sidenav from './sidenav';
import Profilenav from './profile-menu';
import Player from "./Player";

const exclusionArray = [
    '/',
    '/login',
    '/register'
]

export default function Menu({user}) {
    return (
        <div>
            {exclusionArray.indexOf(useLocation().pathname) < 0 && <Sidenav user={user}/>}
            {exclusionArray.indexOf(useLocation().pathname) < 0 && <Profilenav/>}
            {exclusionArray.indexOf(useLocation().pathname) < 0 && <Player user={user}/>}
        </div>
    )
}