import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import AlbumCoverImg from "../Images/AlbumCover.jpg"

export default function List({list}) {

    const navigate = useNavigate();

  return (
    <div className="album">
        <div className="albumcover" style={{backgroundImage: `url(${list.tracks.length > 0? list.tracks[Math.floor(Math.random()*(list.tracks.length-1))].album.cover_url:AlbumCoverImg})`}} >
            <div className="editAlbum" alt="" onClick={() => navigate("/lists/" + list.id)}></div>
        </div>
        <p className='albumTitle'>{list.title}</p>
        <div style={{display: 'flex', gap: "5px"}}>
        <Link onMouseEnter={(e) => e.target.style.textDecoration = "underline"} onMouseLeave={(e) => e.target.style.textDecoration = "none"} style={{flexGrow: "0", flexShrink: "1", flexBasis: "fit-content", margin: "0px", padding: "0px", color: "var(--third-color)", textDecoration: "none", pointerEvents: "auto", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}} to={"/artists/" + list.author.id} className={"link"}>{list.author.username}</Link>
        </div>
    </div>
  )
}
