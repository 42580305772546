import React from 'react';
import { useState } from 'react';
import "../App.css";
import { NavLink, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { db } from '../firebase';

import HomeImg from "../Images/Home.png"
import ExploreImg from "../Images/Search.png"
import LibraryImg from "../Images/Library.png"
import NotificationImg from "../Images/Notification.png"
import Notify from './Notify';
import { ref, get, onValue, update } from 'firebase/database';
import logo from "../Images/Logo.png";
import addNotification from 'react-push-notification';

export default function Sidenav({user}) {
    const [opened, setOpened] = useState(false);
    const [notifies, setNotifies] = useState([]);
    const location = useLocation();
    const navigate = useNavigate();

    async function fetchData() {
        const n = await get(ref(db,"users/" + user.uid + "/notifications"))
        const m = []

        n.forEach((not) => {
            if (not.key != "a") {
                const a = not.val()
                a.id = not.key
                m.push(a)
            }
        })
        setNotifies(Object.values(m).reverse())
    }

    useEffect(() => {
        if (opened == false) {
            document.querySelector("main").style.left = "90px"
            if (document.querySelector(".player")){document.querySelector(".player").style.left = "90px"}
        }
        else {
            document.querySelector("main").style.left = "300px"
            if (document.querySelector(".player")){document.querySelector(".player").style.left = "300px"}
        }
        if (user) {
            fetchData();
            onValue(ref(db,"users/" + user.uid + "/notifications"),() => {fetchData()})
        }
    }, [location, user]);

    async function openMenu() {
        if (opened == true) {
            const n = await get(ref(db,"users/" + user.uid + "/notifications"))

            n.forEach((not) => {
                if (not.key != "a" && not.child("checked").val() == false) {
                    update(ref(db,"users/" + user.uid + "/notifications/" + not.key),{
                        checked: true
                    })
                }
            })
            document.querySelector("main").style.left = "90px"
            if (document.querySelector(".player")){document.querySelector(".player").style.left = "90px"}
        }
        else {
            document.querySelector("main").style.left = "300px"
            if (document.querySelector(".player")){document.querySelector(".player").style.left = "300px"}
        }
        document.querySelector("#menu_button").classList.toggle("change")
        setOpened(!opened)
    }

    return (
    <div className={opened ? "sidenav open": "sidenav"} style={{overflow: "hidden"}}>
        <button id="menu_button" onClick={openMenu}>
            <div className="bar1"></div>
            <div className="bar2"></div>
            <div className="bar3"></div>
        </button>

        <p className="logo">Loudify</p>
        <img className='logoImg' src={logo} style={{position: "fixed", width: "40px", top: "18px", left: "110px", opacity: "1"}}></img>
        <NavLink className="sidenav_button" to="/home">
            <img src={HomeImg} alt="Home"></img>
            Home
        </NavLink>
        <NavLink className="sidenav_button" to="/explore">
            <img src={ExploreImg} alt="Explore"></img>
            Explore
        </NavLink>
        <NavLink className="sidenav_button" to="/library">
            <img src={LibraryImg} alt="Library"></img>
            My Library
        </NavLink>
        <hr/>
        <a className="sidenav_button" onClick={openMenu} style={{margin: 0}}>
            <img src={NotificationImg} alt="Notifications"></img>
            <p style={{display: notifies.filter(a => a.checked == false).length == 0 ? "none":"", position: "fixed", left: "40px", margin: "0", fontSize: "14px", transform: "translate(0,-5px)", backgroundColor: "red", borderRadius: "10px", width: "20px", height: "20px", textAlign: "center"}}>
                {notifies.filter(a => a.checked == false).length}
            </p>
        </a>
        <div style={{marginLeft: "60px", marginRight: "15px", marginBottom: "5px", overflowY: "scroll"}}>
            <p style={{display: notifies.length == 0? "unset": "none", color: "var(--third-color)", fontSize: "14px", whiteSpace: "nowrap"}}>You don't have notifications.</p>
            {notifies.map((not,i) => (
                <Notify key={i} img={not.img} text={not.text} path={not.path} id={not.id} user={user} checked={not.checked}></Notify>
            ))}       
        </div>
    </div>
    )
}