import React, { useEffect, useRef, useState } from 'react';
import { useGlobalAudioPlayer } from 'react-use-audio-player';
import { useLocation, useNavigate } from 'react-router-dom';
import { Howl } from 'howler';

import PlayImg from "../Images/Play.png";
import PauseImg from "../Images/Pause.png";
import { Toast, toMinutesAndSeconds } from '../module';

function useAudioTime() {
  const frameRef = useRef(0);
  const [pos, setPos] = useState(0);
  const { getPosition } = useGlobalAudioPlayer();
  
  useEffect(() => {
    const animate = () => {
      setPos(getPosition());
      frameRef.current = requestAnimationFrame(animate);
    };

    frameRef.current = window.requestAnimationFrame(animate);

    return () => {
      if (frameRef.current) {
        cancelAnimationFrame(frameRef.current);
      }
    };
  }, [getPosition]);
  
  return pos;
}

export default function AudioControlsPreview({ track }) {
  const { load, togglePlayPause, seek, duration, src, playing } = useGlobalAudioPlayer();
  const [active, setActive] = useState(false);
  const [length, setLength] = useState(0);
  const [pos, setPos] = useState(0);
  const [mouseon, setMouseon] = useState(false);
  const audioTime = useAudioTime();

  const location = useLocation();
  const navigate = useNavigate();

  function loadTrack() {
    if (track) {
      navigate(location, {state: { stop: true}})
      load(track, {
        autoplay: true,
        format: "mpeg"
      });
    }
  }

  function changePos(e) {
    const value = e.target.value;
    setPos(value);
  }

  function handleMouseUp(e) {
    setMouseon(false);
    const value = e.target.value;
    if (active) {
      seek(value);
    } else {
      loadTrack();
    }
  }

  useEffect(() => {
    if (src === track) {
      setActive(true);
      setLength(duration);
    } else {
      setActive(false);
    }
  }, [src]);

  useEffect(() => {
    if (track) {
      const sound = new Howl({
        src: [track],
        format:"mpeg",
        onload: () => {
          setLength(sound.duration());
          sound.unload()
        }
      })
    }
  }, [track]);

  if (!track) {
    return (<p></p>);
  }

  return (
    <div className='audioControlsPreview'>
      <img className="play-icon" src={active ? (playing ? PauseImg : PlayImg) : PlayImg} onClick={active ? togglePlayPause : loadTrack}></img>
      <input type="range" min="0" max={length} className='trackRange' onChange={changePos} onMouseDown={() => setMouseon(true)} onMouseUp={handleMouseUp} value={active ? mouseon ? pos: audioTime : 0}></input>
      <span id="duration" className="time">{(active ? toMinutesAndSeconds(audioTime): "0:00") + " / " + toMinutesAndSeconds(length)}</span>
    </div>
  );
}
