import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { createUserWithEmailAndPassword, sendEmailVerification, onAuthStateChanged } from "firebase/auth";
import { ref, set } from "firebase/database";
import { auth, db } from "../firebase";
import { setBackByColor, Toast } from "../module";

export default function Register() {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const [username, setUsername] = useState("");
    const [verified, setIsVerified] = useState(false);

    var errors = {
        "auth/email-already-in-use": "This email address is already in use",
        "auth/invalid-email": "Invalid email address",
        "auth/weak-password": "Password is not strong enough",
        "auth/too-many-requests": "Too many requests sent at the same time, please try again later",
        "auth/user-token-expired": "User token expired",
        "auth/network-request-failed": "Internet connection error"
    };

    async function register(e) {
        e.preventDefault();

        try {
            // Crear usuario con email y contraseña
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
            console.log('User created:', user);

            // Enviar email de verificación
            await sendEmailVerification(user,{
                url: 'https://loudifymusic.com/home',
                handleCodeInApp: true
            });
            console.log('Verification email sent!');
            Toast('Verification email sent! Please check your email.', 3000);

            // Guardar datos del usuario
            await set(ref(db, "users/" + user.uid), {
                username: username,
                email_address: email,
                image_url: "https://firebasestorage.googleapis.com/v0/b/tdrfirebase-cef0a.appspot.com/o/src%2FUser.jpg?alt=media&token=d80d0882-d5d7-410f-b173-d1af15927df3",
                public: false,
                description: "",
                followers: { a: false },
                following: { a: false },
                links: { a: false },
                record: { a: false },
                albums: { a: false },
                lists: { a: false },
                favourite: { a: false },
                notifications: { a: false }
            });

            // Comprobar estado de la verificación
            onAuthStateChanged(auth, (user) => {
                if (user) {
                    user.reload().then(() => {
                        if (user.emailVerified) {
                            setIsVerified(true);
                            navigate('/home');
                        } else {
                            Toast('Please verify your email to continue.', 3000);
                        }
                    });
                }
            });
        } catch (error) {
            console.log(error);
            Toast(errors[error.code], 3000);
        }
    }

    useEffect(() => {
        setBackByColor("var(--main-color)");
    }, []);

    return (
        <div className="body">
            <div className="wrapper">
                <p className="title">Sign up</p>
                <div>
                    <form className="userdata" style={{marginInline: "20%"}} onSubmit={password == passwordConfirm? register: (e) => {e.preventDefault(); Toast("Password does not match with password confirmation",5000)}}>
                        <div className="form">
                            <input id="inputemail" type="email" name="text" autoComplete="off" required value={email} onChange={(e) => setEmail(e.target.value)} />
                            <label htmlFor="text" className="label-name">
                                <span className="content-name">Email address</span>
                            </label>
                        </div>
                        <div className="form">
                            <input id="inputuser" type="text" name="text" autoComplete="off" required value={username} onChange={(e) => setUsername(e.target.value)} />
                            <label htmlFor="text" className="label-name">
                                <span className="content-name">User name</span>
                            </label>
                        </div>
                        <div className="form">
                            <input id="inputpassword" type="password" name="text" autoComplete="off" required value={password} onChange={(e) => setPassword(e.target.value)} />
                            <label htmlFor="text" className="label-name">
                                <span className="content-name">Password</span>
                            </label>
                        </div>
                        <div className="form">
                            <input id="inputpasswordconfirm" type="password" name="text" autoComplete="off" required value={passwordConfirm} onChange={(e) => setPasswordConfirm(e.target.value)} />
                            <label htmlFor="text" className="label-name">
                                <span className="content-name">Confirm password</span>
                            </label>
                        </div>
                        <button type="submit">Register</button>
                    </form>
                </div>
                <hr className="hr2" />
                <p>Already have an account?</p>{" "}
                <NavLink to={"/login"}>Log in</NavLink>
            </div>
        </div>
    );
}
