import React, { useEffect, useState } from 'react';
import { ref, getDatabase, get, onValue, remove, set, update } from 'firebase/database';
import { useList, useListKeys, useObject } from 'react-firebase-hooks/database';
import { auth, db, storage } from '../firebase';
import { Navigate, useNavigate } from 'react-router-dom';

import UploadImg from "../Images/Upload.png"
import RemoveImg from "../Images/Delete.png"
import CoverImg from "../Images/AlbumCover.jpg"
import AudioControls from './AudioControlsPreview';
import AudioControlsPreview from './AudioControlsPreview';
import { uploadBytes, ref as storageRef, getDownloadURL, deleteObject } from 'firebase/storage';
import { Toast } from '../module';
import { Loading } from './Loading';

export default function TrackEdit({id, album}) {

    const [trackData, setData] = useState();
    const [albumData, setAlbumData] = useState();
    const [trackSource, setTrack] = useState();
    const [trackName, setTrackName] = useState("");

    async function changeTrack(e) {
        const file = e.target.files[0]
        if(file) {
            document.querySelector("#tracksourcelabel" + id).querySelector("p").textContent = file.name
            await uploadBytes(storageRef(storage, "tracks/" + album + "/" + id),file).then(async () => {
                await getDownloadURL(storageRef(storage, "tracks/" + album + "/" + id)).then(async(url) => {
                    await update(ref(db,"tracks/" + id),{
                        source: url
                    })
                    setTrack(url)
                })
            })
        }
    }

    async function deleteTrack() {
        try {
            await deleteObject(storageRef(storage,"tracks/" + album + "/" + id))
        }
        catch (err) {
            console.log("Source does not exist")
        }

        await remove(ref(db,"albums/" + album + "/tracks/" + id))
        await remove(ref(db,"tracks/" + id))
    }

    async function setName(e) {
        setTrackName(e.target.value);
        await update(ref(db,"tracks/" + id), {
            name: e.target.value
        })
    }

  useEffect(() => {
    const fetchData = async () => {
        const data = await get(ref(db, "tracks/" + id));
        const albumSnap = await get(ref(db, "albums/" + album));
        setData(data);
        setAlbumData(albumSnap);
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (trackData) {
        setTrack(trackData.child("source").val());
        setTrackName(trackData.child("name").val());
    }
  }, [trackData]);

  if (!trackData) {
    return (
    <div className="track">
        <Loading />
    </div>)
  };

  return (
    <div className="track">
        <div className="container">
            <img src={RemoveImg} onClick={deleteTrack} className='deleteTrack'></img>
            <p id="tracknumber">{albumData.child("tracks").child(id).val() + "."}</p>
            <img id="trackcover" src={albumData.child("cover_url").val()} alt=""></img>
            <div id="tracksettings">
                <input value={trackName} onChange={setName} id="tracknameinput" type="text" name="title" placeholder="Name" autoComplete="off" required></input>
                <AudioControlsPreview track={trackSource} />
            </div>
            
            <input id={"tracksource_" + id} className='tracksource' type="file" accept="audio/mpeg" onChange={changeTrack}></input>
            <label id={"tracksourcelabel" + id} className='tracksourcelabel' htmlFor={"tracksource_" + id}>
                <img src={UploadImg} alt=""></img>
                <p>File</p>
            </label>
        </div>
    </div>
  );
};