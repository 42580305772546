import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import AudioControls from './AudioControls';
import { useGlobalAudioPlayer } from 'react-use-audio-player';
import { push, ref, set, update } from 'firebase/database';
import { db } from '../firebase';
import SpanImg from "../Images/Arrow.png";
import Track from './track';
import { setBackColor } from '../module';

export default function Player({user}) {
    const location = useLocation();
    const navigate = useNavigate();
    const [state, setState] = useState({});
    const { load, stop, duration, playing, seek } = useGlobalAudioPlayer();
    const [songPlaying, setPlaying] = useState(false);
    const [fullPage, setFullPage] = useState(false);
    const [backColorSave, setBackColorSave] = useState("");
    const [random, setRandom] = useState(false);
    const [prev, setPrev] = useState(0);

    function previous() {
        if (state.play?.[state.index - 1]) {
          navigate(location, {state: { play: state.play, index: state.index - 1 }})
        }
      }

    function next() {
        if (random) {
          let r = Math.floor(Math.random() * state.play.length)
          while (r == state.index) {
            r = Math.floor(Math.random() * state.play.length)
          }
          navigate(location, {state: { play: state.play, index:r}})
        }else{
        if(state.play?.[state.index + 1]) {
          navigate(location, {state: { play: state.play, index:  state.index + 1 }})
        } else {
          seek(duration)
        }}
        setPrev(state.index)
    }

    useEffect(() => {
        if (location.state?.play) {
            setState(location.state)
            navigate(location)
        } else {
            if (location.state?.stop) {
                setPlaying(false)
                navigate(location)
            }
        }
        navigate(location)
    },[location.state])

    useEffect(() => {
        stop()

        if (state?.play && state.play?.[state.index]) {
            setPlaying(true)
            if (fullPage) {setBackColor(state.play[state.index].album.cover_url)}
            if (!user) {
                navigate("login")
            }
            else{
            load(state.play[state.index].source, {
                autoplay: true,
                format: "mpeg",
                onload: async () => {
                    await update(ref(db,"tracks/" + state.play[state.index].id),{
                        plays: state.play[state.index].plays + 1
                    });
                    await set(push(ref(db,"users/" + user.uid + "/record")),{
                        id: state.play[state.index].id,
                        list: state.play[state.index].list,
                        date: (new Date()).toString()
                    })
                },
                onend: next
            });
            }
        }
        else {
            setPlaying(false)
        }
    },[state])

    useEffect(() => {
        if (songPlaying && !fullPage) {
            document.querySelector("main").style.bottom = "95px"
        }
        if (state?.play) {
            document.querySelectorAll(".trackdisplayname").forEach((display) => {
                display.style.color = "white"
            })
            document.querySelectorAll("." + state.play[state.index].id).forEach((display) => {
                display.querySelector(".trackdisplayname").style.color = "var(--selected)"
            }) 
        }
    },[songPlaying, fullPage, location, state])

    useEffect(() => {
        if (fullPage) {
            setFullPage(false)
        }
    },[location.pathname])

    if (!songPlaying) {
        return 
    }

    if (fullPage) {
        return (
            <main className='playerFullScreen' >
                <img className='contract' src={SpanImg} onClick={() => {
                    document.querySelector("#backColor").style.backgroundImage = backColorSave;
                    document.querySelector("#main").style.display = "unset";
                    setFullPage(!fullPage);
                }}></img>
                <div className='leftframe'><div className='innerframe'>
                    <p className='title'>Listening to </p><p className='listName'>{state.play[state.index].list.name}</p>
                    <div>
                        {state.play.map((track, index) => (
                            <div key={track.id} onClick={() => setState({play: state.play, index: index})}>
                                <Track index={index + 1} track={track} user={user}/>
                            </div>
                        ))}
                    </div>
                    <br></br>
                </div></div>
                <div className='rightframe'>
                    <AudioControls fullPage={fullPage} state={state} next={next} previous={previous} random={random} setRandom={setRandom} /> 
                </div>
            </main>
        )
    }
  return (
    <div className='player'>
    <div className='playerFrame'>
        <div style={{position: "absolute", height: "100%", width: "100%", top: "0", zIndex:2}} onClick={() => {
            document.querySelector("main").id = "main"
            document.querySelector("main").style.display = "none"
            setFullPage(!fullPage)
            setBackColorSave(document.querySelector("#backColor").style.backgroundImage)
            setBackColor(state.play[state.index].album.cover_url)
        }}></div>
        <AudioControls fullPage={fullPage} state={state} next={next} previous={previous} random={random} setRandom={setRandom} />   
    </div>
    </div>
  )
}
