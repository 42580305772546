import React, { useEffect, useRef, useState } from 'react';
import { useGlobalAudioPlayer } from 'react-use-audio-player';
import { Howl } from 'howler';

import PlayImg from "../Images/Play.png";
import PauseImg from "../Images/Pause.png";
import CoverImg from "../Images/AlbumCover.jpg";
import RandomImg from "../Images/Random.png";
import BackImg from "../Images/Back.png";
import NextImg from "../Images/Next.png";
import SpanImg from "../Images/Arrow.png";
import { Toast, toMinutesAndSeconds } from '../module';
import { Link, useLocation, useNavigate } from 'react-router-dom';

function useAudioTime() {
  const frameRef = useRef(0);
  const [pos, setPos] = useState(0);
  const { getPosition } = useGlobalAudioPlayer();
  
  useEffect(() => {
    const animate = () => {
      setPos(getPosition());
      frameRef.current = requestAnimationFrame(animate);
    };

    frameRef.current = window.requestAnimationFrame(animate);

    return () => {
      if (frameRef.current) {
        cancelAnimationFrame(frameRef.current);
      }
    };
  }, [getPosition]);
  
  return pos;
}

export default function AudioControls({ fullPage, state, next, previous, random, setRandom}) {
  const { load, togglePlayPause, stop, seek, playing } = useGlobalAudioPlayer();
  const [pos, setPos] = useState(0);
  const [mouseon, setMouseon] = useState(false);
  const [duration, setDuration] = useState(0);
  const audioTime = useAudioTime();

  const location = useLocation();
  const navigate = useNavigate();

  function changePos(e) {
    const value = e.target.value;
    setPos(value);
  }

  function handleMouseUp(e) {
    setMouseon(false);
    const value = e.target.value;
    seek(value);
  }

  useEffect(() => {
    if (state?.play) {
      const song = new Howl({
        src: [state.play[state.index].source],
        format: "mpeg",
        html5: true,
        onload: () => {
          setDuration(song.duration());
          song.unload();
        }
      })
    }
  }, [state]);

  if (!state?.play) {
    return
  }

  if (fullPage) {
    return (
      <div className='audioControls' style={{flexDirection: "column", justifyContent: "center", alignItems: 'center'}}>
        <img className='album_cover' src={state.play[state.index].album.cover_url} style={{height: "70%", maxWidth: "90%",margin: "50px", float: "unset", objectFit: "contain"}}></img>
        <img src={RandomImg} style={{opacity: random? "1":"0.3", height: "35px", width: "30px", translate: "0 -15px"}} onClick={() =>{
          setRandom(!random);
        }}></img>
        <div style={{margin: "20px", position: "relative", translate: "0 -30px"}}>
          <div>
              <p className="currentTime">{toMinutesAndSeconds(audioTime)}</p>
              <p className='songName'>{state.play[state.index].name}</p>
              <img className="play-icon" src={BackImg} onClick={audioTime <= 1? previous: () => seek(0)}></img>
              <img className="play-icon" src={playing ? PauseImg  : PlayImg} onClick={togglePlayPause}></img>
              <img className="play-icon" src={NextImg} onClick={next}></img>
              <p className="duration">{toMinutesAndSeconds(duration)}</p>
          </div>
          <div><input type="range" step="any" id="seek-slider" min="0" max={duration} onChange={changePos} onMouseDown={() => setMouseon(true)} onMouseUp={handleMouseUp} value={mouseon ? pos: audioTime}
          style={{
            background: `linear-gradient(to right, white ${(mouseon ? pos: audioTime)/duration * 100}%, #535353 ${(mouseon ? pos: audioTime)/duration * 100}%)`
          }}
          ></input></div>
          <span style={{position: "absolute", display: "flex", top: "-17px", right: "0px", gap: "5px", width: "40%", flexDirection: "row-reverse"}}>
              <Link onMouseEnter={(e) => e.target.style.textDecoration = "underline"} onMouseLeave={(e) => e.target.style.textDecoration = "none"} style={{flexGrow: "0", flexShrink: "1", flexBasis: "fit-content", margin: "0px", padding: "0px", color: "var(--third-color)", textDecoration: "none", pointerEvents: "auto", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}} to={"/artists/" + state.play[state.index].artist.id} className={"link"}>{state.play[state.index].artist.username}</Link>
              <p style={{margin: "0px", padding: "0px", color: "var(--third-color)", whiteSpace: "nowrap"}}>·</p>
              <Link onMouseEnter={(e) => e.target.style.textDecoration = "underline"} onMouseLeave={(e) => e.target.style.textDecoration = "none"} style={{flexGrow: "0", flexShrink: "1", flexBasis: "fit-content", margin: "0px", padding: "0px", color: "var(--third-color)", textDecoration: "none", pointerEvents: "auto", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}} to={"/albums/" + state.play[state.index].album.id} className={"link"}>{state.play[state.index].album.title}</Link>
          </span>
        </div>
      </div>
    );
  }

  return (
    <div className='audioControls'>
      <img className='album_cover' src={state.play[state.index].album.cover_url}></img>
      <div>
        <div>
            <p className="currentTime">{toMinutesAndSeconds(audioTime)}</p>
            <p className='songName'>{state.play[state.index].name}</p>
            <img className="play-icon" src={BackImg} onClick={audioTime <= 1? previous: () => seek(0)}></img>
            <img className="play-icon" src={playing ? PauseImg  : PlayImg} onClick={togglePlayPause}></img>
            <img className="play-icon" src={NextImg} onClick={next}></img>
            <p className="duration">{toMinutesAndSeconds(duration)}</p>
            <img className='span' src={SpanImg}></img>
        </div>
        <div><input type="range" step="any" id="seek-slider" min="0" max={duration} onChange={changePos} onMouseDown={() => setMouseon(true)} onMouseUp={handleMouseUp} value={mouseon ? pos: audioTime}
        style={{
          background: `linear-gradient(to right, white ${(mouseon ? pos: audioTime)/duration * 100}%, rgb(120,120,120) ${(mouseon ? pos: audioTime)/duration * 100}%)`
        }}
        ></input></div>
      </div>
    </div>
  );
}
