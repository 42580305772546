import React from 'react'

import LoadingImg from "../Images/Loading.png"

export const Loading = () => {
  return (
    <div id='loadingFrame' style={{height:"100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
        <img src={LoadingImg} className='loadingImg'></img>
    </div>
  )
}
