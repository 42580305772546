import React, { useEffect, useState } from 'react'
import "../App.css"
import { auth, db } from '../firebase'
import { onAuthStateChanged, signOut } from 'firebase/auth'
import { NavLink } from 'react-router-dom'
import { ref, onValue } from 'firebase/database'
import UserImg from '../Images/User.jpg'

export default function Profilenav() {

  const [logged, setLogged] = useState(false)
  const [opened, setOpened] = useState(false)
  const [imgSrc, setImgSrc] = useState(UserImg)
  const [username, setUsername] = useState("Username")

  useEffect( () => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        setLogged(true)
        onValue(ref(db, "users/" + user.uid + "/image_url"), (snapshot) => {
          setImgSrc(snapshot.val())
        })

        onValue(ref(db, "users/" + user.uid + "/username"), (snapshot) => {
          setUsername(snapshot.val())
        })
      }
      else {
        setLogged(false)
      }
    })
  },[])

  function openFrame() {
    setOpened(!opened)
  }

  function logout() {
    signOut(auth)
  }

  return (
    <div className='profilenav'>
        <div id="overlay" style={{visibility: opened ? "visible": "hidden"}} onClick={openFrame}></div>
        <div id="register" style={{visibility: logged ? "hidden": "visible"}}>
            <NavLink id="signup" to={"/register"}>Sign up</NavLink>
            <NavLink id="login" to={"/login"}>Log in</NavLink>
        </div>
        <div id="account" style={{visibility: logged ? "visible": "hidden"}}>
            <img id="accountimg" className={opened ? "open": ""} src={imgSrc} alt="" onClick={openFrame}></img>
            <div id="accountframe" style={{display: opened ? "block": "none"}}>
                <button id="profileclose" onClick={openFrame}>x</button>
                <p className="btn" id="username">{username}</p>
                <hr></hr>
                <NavLink to={"/profile"} onClick={openFrame}>Profile</NavLink>
                <NavLink id="logout" to={"/home"} onClick={logout}>Log out</NavLink>
                <hr></hr>
            </div>
        </div>

    </div>
  )
}
